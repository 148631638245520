import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Badge, Button, Card, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import { Check, DownloadCloud, Edit2, Plus, RefreshCw, Send, Trash } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import AssetCategories from "../Helper/AssetCategories";
import UserDataManager from "../Helper/UserDataManager";
import { de } from "date-fns/locale";
import { getCoreBaseURL } from "../../config";
import LoadingSpinner from "../Helper/LoadingSpinner";
import AddPaidStatusModal from "./AddPaidStatusModal";
import { isMobile } from "react-device-detect";
import html2pdf from 'html2pdf.js'; // Import the html2pdf library
import usePalette from "../../hooks/usePalette";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import AddInvoiceNumberModal from "./AddInvoiceNumberModal";

const InvoiceDetailsModal = ({ selected_invoice_id, handleCancel, handleClose, contract_id }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const palette = usePalette();
  const [data, setData] = useState([]);
  const [contractData, setContractData] = useState();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);
  const notyf = useContext(NotyfContext);

  useEffect(() => {

    setIsLoading(true);

    loadInvoice();

  }, []);

  function loadInvoice() {


    axios
      .get("/webservice/invoices/" + selected_invoice_id)
      .then(function (response) {
        // handle success
        console.log(response.status, "/invoice");
        setData(response.data);
        setIsLoading(false);

        axios
          .get("webservice/contracts/" + response.data?.contract_id)
          .then(function (response) {
            // handle success
            console.log(response.status, "/contracts");
            setContractData(response.data);
          })
          .catch(function (error) {
            // handle error
            setError({ showMessage: true, errorData: error })
            console.log(error);
          });
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });
  }

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/invoices/" + selected_invoice_id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted invoice");
        handleClose();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  // function handleSendEmail(e) {
  //   e.preventDefault();

  //   axios
  //     .post(`/invoices/${selected_invoice_id}/billing_mail`, {
  //       params: {
  //         add_self_to_cc: true
  //       }
  //     })
  //     .then(function (response) {
  //       // handle success
  //       console.log(response.status, "Invoice sent", response.data);

  //       handleClose();

  //       notyf.open({
  //         type: "success",
  //         message: t("Sent email notification including invoice PDF."),
  //         duration: "9000",
  //         ripple: false,
  //         dismissible: true,
  //         position: {
  //           x: "right",
  //           y: "bottom",
  //         },
  //       })
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       setError({ showMessage: true, errorData: error })
  //       console.log(error);
  //     });
  // }

  function handlePublish(e) {
    e.preventDefault();

    axios
      .post(`/invoices/${selected_invoice_id}/publish`, {
        params: {
          add_self_to_cc: false,
          send_billing_mail: false,
          add_self_to_bcc: true,
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Invoice sent", response.data);

        handleClose();

        notyf.open({
          type: "success",
          message: t("Invoice is now visible for the client and was notified via email. You are in CC."),
          duration: "10000",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const loadAssetBills = () => {
    if ("asset_bills" in data) {
      return data["asset_bills"].map((invoice) => {
        return (
          <tr key={invoice.id}>
            <td>{t("Usage period")} {format(parseISO(invoice["billing_period_start"]), `d${i18n.language === "de" ? "." : ""} MMMM yyyy`, { locale: i18n.language === "de" ? de : null })} {t("until")} {format(parseISO(invoice["billing_period_end"]), `d${i18n.language === "de" ? "." : ""} MMMM yyyy`, { locale: i18n.language === "de" ? de : null })}</td>
            {/* <td>{invoice["base_fee_amount"].toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td> */}
            <td>{invoice["per_use_price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
            <td>{invoice["usage_units"]?.toLocaleString()}</td>
            <td className="text-end">{invoice["usage_amount"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    } else {
      return null;
    }
  };

  const loadPositions = () => {
    if (data?.positions?.length > 0) {
      return data?.positions?.map((fee, indx) => {
        return (
          <tr key={indx}>
            <td>{fee["name"] === "usage" ? t("Asset Usage Fee") : t(fee["name"])}</td>
            <td>{fee["price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
            <td>{fee["quantity"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal',
            })}</td>
            <td className="text-end">{fee["total"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <td>&nbsp;</td>
        </tr>
      );
    }
  };

  const loadAssetAdditionalFees = () => {
    if (data?.additional_items?.length > 0) {
      return (
        <>
          <tr>
            <th>{t("Additional fees")}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          {data?.additional_items?.map((fee, indx) => (
            <tr key={indx}>
              <td>{fee["name"]}</td>
              <td>{fee["price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency',
                currency: 'EUR',
              })}</td>
              <td>{fee["quantity"]?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
              })}</td>
              <td className="text-end">{fee["total"]?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency',
                currency: 'EUR',
              })}</td>
            </tr>
          ))}
        </>
      );
    }
    return null; // Return null instead of an empty <div>
  };

  const renderInvoiceState = (invoice) => {

    // console.log("-->", invoice.payment_status)

    switch (invoice.payment_status) {
      case "paid":
        return <Badge bg="" className="badge-soft-success me-2">{t(invoice.payment_status)}</Badge>

      case "unpaid":

        if (invoice?.sent_to_billing_date) {
          return <Badge bg="" className="badge-soft-warning me-2">{t("Sent")} | {t(invoice.payment_status)}</Badge>
        } else {
          return <Badge bg="" className="badge-soft-warning me-2">{t(invoice.payment_status)}</Badge>
        }

      case "overdue":

        if (invoice?.sent_to_billing_date) {
          return <Badge bg="" className="badge-soft-danger me-2">{t("Sent")} | {t(invoice.payment_status)}</Badge>
        } else {
          return <Badge bg="" className="badge-soft-danger me-2">{t(invoice.payment_status)}</Badge>
        }

      case "unpublished":
        return <Badge bg="" className="badge-soft-secondary me-2">{t(invoice.payment_status)}</Badge>

      default:
        return <Badge bg="" className="badge-soft-primary me-2">{t(invoice.payment_status)}</Badge>
    }
  }

  const loadHeader = () => {

    var total = data.total;
    var due_date = data.due_date;
    var contract_id = data.contract_id;

    return (
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">

                <OverlayTrigger
                  placement='bottom'
                  overlay={<Popover>
                    <Popover.Header as='h3'>{t("Status")}</Popover.Header>
                    <Popover.Body>
                      {data?.sent_to_billing_date ? format(parseISO(data?.sent_to_billing_date), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : t("No email has been sent to client")}
                    </Popover.Body>
                  </Popover>}>
                  <h3 className="mb-2">
                    {renderInvoiceState(data)}
                  </h3>
                </OverlayTrigger>

                <p className="mb-0">{t("Status")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{total == null ? "" : total.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  currency: 'EUR',
                })}</h3>
                <p className="mb-0">{t("Amount")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{due_date == null ? "" : format(parseISO(due_date), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null })}</h3>
                <p className="mb-0">{t("Due date")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill" onClick={() => navigate("/contracts/" + contract_id)} style={{ cursor: "pointer" }}>
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{t("View Contract")}</h3>
                <p className="mb-0">{t("Contract")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row >
    )
  }

  function downloadReport() {
    const element = document.getElementById('invoice-content');

    const opt = {
      margin: [0, 0, 0, 0], // Adjust margins
      filename: `${format(parseISO(data?.created_at), "yyyyMMdd")}_Invoice_${data?.invoice_number}_${data?.billing_party?.name}_cap-on.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 }, // Lower scale to fit content better
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };

    // Add a slight delay to ensure the chart is fully rendered before generating the PDF
    setTimeout(() => {
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get('pdf')
        // .then((pdf) => {
        //   const totalPages = pdf.internal.getNumberOfPages();
        //   for (let i = 1; i <= totalPages; i++) {
        //     pdf.setPage(i);
        //     pdf.setFontSize(10);
        //     pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 1, pdf.internal.pageSize.getHeight() - 0.5); // Add page numbers at the bottom
        //   }
        // })
        .save();
    }, 500);  // 500ms delay to ensure everything is rendered before capturing
  }

  function openInNewTab(url) {
    const win = window.open(url, '_blank'); // Open in a new tab
    if (win) {
      win.focus();  // Ensure the new tab gets focus
    } else {
      // Handle cases where the pop-up is blocked
      alert('Please allow pop-ups for this site.');
    }
  }

  const [showPaidStatus, setShowPaidStatus] = useState(false)
  const [updateInvoiceNumber, setUpdateInvoiceNumber] = useState(false)

  return (
    <Modal size="xl"
      show
      onHide={handleCancel}
      style={{
        zIndex: 1050, // Lower than the Mark as "Paid" modal
      }}>
      <Modal.Header closeButton>{t("Invoice")}</Modal.Header>
      <Modal.Body className="m-0"
        style={{ backgroundColor: palette["gray-200"], padding: 20, margin: 0 }}
      >

        {showPaidStatus && <AddPaidStatusModal invoice={data}
          handleSave={() => {
            setShowPaidStatus(false)
            loadInvoice();
          }}
          handleCancel={() => {
            setShowPaidStatus(false)
          }} />}

        {updateInvoiceNumber && <AddInvoiceNumberModal invoice={data}
          handleSave={() => {
            setUpdateInvoiceNumber(false)
            loadInvoice();
          }}
          handleCancel={() => {
            setUpdateInvoiceNumber(false)
          }} />}


        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        {!isLoading && loadHeader()}


        <Row className="mb-2">
          <Col xs="auto" className="d-none d-sm-block">

            <span>
              <h3>{t("Invoice")}</h3>
            </span>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">

            {(contractData?.user_permission_level === "contract_manager" || contractData?.user_permission_level === "finance_agent") && <  >
              <Button
                onClick={() => setUpdateInvoiceNumber(true)}
                variant="outline-primary"
                size="sm"
                className="shadow-sm me-1">
                <Edit2 className="feather" /> {!isMobile && t("Update Invoice Number")}
              </Button>

              <Button
                variant={"outline-primary"}
                onClick={() => setShowPaidStatus(true)}
                size="sm"
                className="shadow-sm me-1"
              >
                <Check className="feather" /> {t("Update Payment Status")}
              </Button>
            </>}

            {
              data?.pdf_url &&
              // ?
              <Button
                onClick={() => openInNewTab(data?.pdf_url)}
                variant="outline-primary"
                size="sm"
                className="shadow-sm me-1">
                <DownloadCloud className="feather" /> {!isMobile && t("Download PDF")}
              </Button>
              // : <Button
              //   onClick={() => downloadReport()}
              //   variant="primary-outline"
              //   size="sm"
              //   className="shadow-sm me-1">
              //   <DownloadCloud className="feather" /> {!isMobile && t("Create PDF")}
              // </Button>
            }

            {/* {data?.pdf_url && data?.payment_status !== "draft" && <Button
              variant="primary"
              size="sm"
              onClick={e => window.confirm(t("Are you sure you want to send the invoice via email to the client's billing email address?")) && handleSendEmail(e)}
              className="shadow-sm me-1"
            >
              <Send className="feather" /> {t("Re-Send Invoice to Client")}
            </Button>} */}

            {(contractData?.user_permission_level === "contract_manager" || contractData?.user_permission_level === "finance_agent") && <Button
              variant="primary"
              size="sm"
              onClick={e => window.confirm(t("Are you sure you want to send the invoice via email to the client's billing email address?")) && handlePublish(e)}
              className="shadow-sm me-1"
            >
              <Send className="feather" /> {data?.payment_status === "draft" ? t("Publish") : t("Re-Send Invoice to Client")}
            </Button>}

            {UserDataManager.getInstance().isCapOn() && <Button
              variant="danger"
              size="sm"
              onClick={e => window.confirm(t("Are you sure you wish to delete this invoice?")) && handleDelete(e)}
              className="shadow-sm "
            >
              <Trash className="feather" />
            </Button>}
          </Col>
        </Row>

        {isLoading ? <LoadingSpinner /> :
          <>
            <style>
              {`
          #invoice-content {
            font-size: 12px; /* Reduce font size for compactness */
          }

          #invoice-content table {
            width: 100%; /* Ensure table takes full width */
            table-layout: auto; /* Auto-adjust column width */
          }

          #invoice-content th, #invoice-content td {
            padding: 4px; /* Compact table cells */
          }

          @media print {
            #invoice-content {
              font-size: 10px; /* Even smaller for print optimization */
            }

            .pagebreak {
              page-break-before: always;
            }

            .text-end {
              text-align: right;
            }
          }
        `}
            </style>

            <div id="invoice-content">
              <Row>
                <Col md="12" >
                  <Card>
                    <Card.Body className="m-sm-3 m-md-3">

                      {data?.billing_party?.avatar_url && data?.billing_party?.avatar_url !== "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png" && <Row>
                        {/* Avatar Section */}
                        <Col md="3" className="ms-auto text-end mb-2">
                          <div style={{ marginBottom: "10px" }}>
                            {data?.billing_party?.avatar_url && <img
                              src={data?.billing_party?.avatar_url}
                              style={{
                                height: "150px",
                                width: "auto",
                                maxWidth: "300px",
                                objectFit: "cover",
                                // borderRadius: "50%",
                                // border: "1px solid #ddd",
                                padding: "5px",
                              }}
                              alt="Company Avatar"
                            />}
                          </div>
                        </Col>
                      </Row>}

                      <Row className="mt-5">
                        {/* Address Section */}
                        <Col md="3" className="ms-auto text-end">
                          <strong>{data?.billing_party?.name}</strong>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.billing_party?.address?.split(", ").join("<br />"),
                            }}
                          />
                          {/* <a href="mailto:support@cap-on.de">support@cap-on.de</a> */}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <strong>{data?.billed_party?.name}</strong>
                          <p dangerouslySetInnerHTML={{ __html: data?.billed_party?.address?.split(", ").join("<br />") }} />
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col md="12">
                          <div className="h4">{t("Invoice")} {data?.invoice_number_set}</div>
                        </Col>
                      </Row>

                      <hr className="my-4" />

                      <Row className="mt-4 mb-4">
                        <Col>
                          <div md="6" className="d-none d-sm-block">
                            {t("Dear Sir or Madam,")}
                            <br />
                            <br /> {t("This is the monthly receipt regarding the aforementioned contract number. Please settle the amount until the given due date.")}
                          </div>
                        </Col>
                        <Col md="6" className="ms-auto text-end mt-n1">
                          <Row>
                            <div className="text-muted">{t("Contract No.")}: <strong>{data.contract_number_set || "-"}</strong></div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("Invoice No.")}: <strong>{data.invoice_number_set || "-"}</strong></div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("Invoice Period")}: <strong>
                              {data.invoice_period_start == null ? "" : format(parseISO(data.invoice_period_start), `d${i18n.language === "de" ? "." : ""} MMMM yyyy, HH:mm`, { locale: i18n.language === "de" ? de : null })}
                            </strong>{" "}
                              {t("until")} <strong>
                                {data.invoice_period_end == null ? "" : format(parseISO(data.invoice_period_end), `d${i18n.language === "de" ? "." : ""} MMMM yyyy, HH:mm`, { locale: i18n.language === "de" ? de : null })}
                              </strong>{" "}</div>
                          </Row>
                          <Row >
                            <div className="text-muted">{t("Invoice date")}: <strong>{data.created_at == null ? "" : format(parseISO(data.created_at), `d${i18n.language === "de" ? "." : ""} MMMM yyyy`, { locale: i18n.language === "de" ? de : null })}</strong></div>
                          </Row>
                          <Row >
                            <div className="text-muted">{t("Due date")}: <strong>{data.due_date == null ? "" : format(parseISO(data.due_date), `d${i18n.language === "de" ? "." : ""} MMMM yyyy`, { locale: i18n.language === "de" ? de : null })}</strong></div>
                          </Row>
                        </Col>
                      </Row>

                      <Table size="sm" className="mt-2">
                        <thead>
                          <tr>
                            <th>{t("Description")}</th>
                            <th>{t("Per-Use-Price")}</th>
                            <th>{t("Quantity")}</th>
                            <th className="text-end">{t("Amount")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>{t("Asset fees")}</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                          </tr>

                          {/* {loadAssetBills()} */}
                          {loadPositions()}

                          {/* {data?.additional_items?.length > 0 && <tr>
                <th>{t("Additional fees")}</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>} */}


                          {loadAssetAdditionalFees()}
                          <tr>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>{t("Subtotal")}</th>
                            <th className="text-end">{data.subtotal == null ? "0" : data.subtotal.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'currency',
                              currency: 'EUR',
                            })}</th>
                          </tr>
                          <tr>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>{t("VAT")}</th>
                            <th className="text-end">{data.vat == null ? "0" : (data.vat).toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'currency',
                              currency: 'EUR',
                            })}</th>
                          </tr>
                          <tr>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>{t("Total")}</th>
                            <th className="text-end">{data.total == null ? "0" : (data.total).toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'currency',
                              currency: 'EUR',
                            })}</th>
                          </tr>
                        </tbody>
                      </Table>
                      {/* <br />
          <br />
          <div className="text-center">
            <Button variant="primary">{t("Print invoice")}</Button>
          </div> */}


                      {/* <hr className="my-4" /> */}

                      <br />

                      <Row className="mt-4 mb-4">
                        <Col md="4">
                          <Row>
                            <div className="text-muted">{data?.billing_party?.business_name || data?.billing_party?.name || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{data?.billing_party?.address || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{data?.billing_party?.website || "-"}</div>
                          </Row>

                        </Col>

                        <Col md="4" className="text-center">
                          {/* <Row>
                              <div className="text-muted">{data?.billing_party?.account_holder || "-"}</div>
                            </Row> */}
                          <Row>
                            <div className="text-muted">{t("IBAN")}: {data?.billing_party?.bank_reference || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("BIC/Swift")}: {data?.billing_party?.swift_code || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("Bank")}: {data?.billing_party?.bank_name || "-"}</div>
                          </Row>
                        </Col>

                        <Col md="4" className="text-end">
                          <Row>
                            <div className="text-muted"> {data?.billing_party?.registration_number || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("TIN")} {data?.billing_party?.tax_number || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{t("VAT-ID")} {data?.billing_party?.vat_id || "-"}</div>
                          </Row>
                          <Row>
                            <div className="text-muted">{data?.billing_party?.description || ""}</div>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <div className="text-center mt-1 mb-1">
                            <span>Powered by <img className="mb-1" src={mainLogo} alt="cap-on" style={{ height: "50px", paddingTop: "10px", paddingBottom: "10px" }} />
                            </span>
                          </div>
                        </Col>

                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>}
      </Modal.Body >
    </Modal >
  );
};

export default InvoiceDetailsModal;
