import React, { useEffect, useState, memo, useCallback, useMemo } from "react";
import { Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { format, formatDistance, parseISO } from "date-fns";
import { de, enUS } from "date-fns/locale";
import usePalette from "../../../hooks/usePalette";
// import { writeFile } from "xlsx";
import json2csv from 'json2csv';
import { HelpCircle, RefreshCw } from "react-feather";
import Chart from "react-apexcharts";
import Select from "react-select";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import WatchTowerList from "../../Watchtower/WatchTowerList";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import useTheme from "../../../hooks/useTheme";
import { debounce } from 'lodash';

const AssetDigitalTwinChartModal = ({ desired_start_time, desired_end_time, asset_data, asset_id, desired_iot_node_id, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  // const aWeekAgo = new Date(new Date() - 24 * 60 * 60 * 1000 * 7) // new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)

  const startDate = new Date(new Date() - 12 * 60 * 60 * 1000)
  const now = new Date()


  const [isLoading, setIsLoading] = useState(true);

  const [subtitle, setSubtitle] = useState("");

  const [showRawData, setShowRawData] = useState();

  const palette = usePalette();

  const [showInOneChart, setShowInOneChart] = useState(true);
  /// COMPOSER
  const [IoTNodesForComparison, setIoTNodesForComparison] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [foundIoTNodeData, setFoundIoTNodeData] = useState();

  // Get the browser's current time zone
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimeZone, setSelectedTimeZone] = useState(browserTimeZone);
  // const [reset, setReset] = useState(false);

  // Handler for when the selection changes
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []); // Update the state with the selected options
    fetchDataClustered(selected, null, null)
  };

  function handleChangeTimeZone(event) {
    setSelectedTimeZone(event.target.value);
  }

  function handleChangeDates(intervalInHours) {
    console.log("Handle change dates");

    // Calculate the interval start and end times
    const intervalStart = new Date(Date.now() - intervalInHours * 60 * 60 * 1000);
    const intervalEnd = new Date();

    // Convert dates to the correct datetime-local format
    const intervalStartFormatted = toDateTimeLocalString(intervalStart);
    const intervalEndFormatted = toDateTimeLocalString(intervalEnd);

    // Update the pickers and the state
    handleChangeStartDatePicker(intervalStartFormatted);
    handleChangeEndDatePicker(intervalEndFormatted);

    // Fetch data using ISO string format for the backend
    fetchDataClustered(null, intervalStart.toISOString(), intervalEnd.toISOString());
  }

  useEffect(() => {

    // console.log("--->", desired_start_time, desired_end_time, startDate, now)

    console.log("Step 1) Open chart modal for id", desired_iot_node_id)

    setIsLoading(true);

    axios
      .get("/assets/" + asset_id + "/iot_nodes/", {
        params: {
          unit_language: i18n.language === "us" ? "us" : "de"
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/iot_nodes");

        // console.log("Desired iot_node_id:", desired_iot_node_id)
        let desired_node_data = response.data?.find((iot_node_id_data) => iot_node_id_data?.id === desired_iot_node_id)
        setFoundIoTNodeData(desired_node_data)

        console.log("Step 1.1) Found node data info for ", desired_node_data?.log_type?.technical_name)

        const formattedOptions = response.data
          // ?.filter((item) => item.log_type.unit !== "bool")
          ?.map(item => ({
            value: item.type_number, // or any unique identifier
            label: `${item?.log_type?.technical_name || item?.log_type?.display_name || item.log_type?.plc_node_id} ${item?.log_type?.unit && item?.log_type?.unit !== "state" ? `(${renderUnit(item?.log_type?.unit)})` : ""}`,
            // state_names: item?.log_type?.state_names || []
          }));

        setIoTNodesForComparison(formattedOptions.sort((a, b) => (a.label > b.label)));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // setIsLoading(false);
      });

  }, []);

  useEffect(() => {

    setError({ showMessage: false, errorData: null })

    if (foundIoTNodeData) {

      console.log("Step 1.2) Found desired Node-Data")

      setSelectedOptions([]);

      // Ensure chart is rendered before interacting
      // setTimeout(() => {
      //   // Your chart interactions or event binding here
      //   console.log("Chart is ready for interaction.");
      // }, 500); // Delay to ensure rendering

      // setTitle(`${t(foundIoTNodeData?.log_type?.technical_name || foundIoTNodeData?.log_type?.display_name || "n/a")} ${foundIoTNodeData?.log_type?.unit && foundIoTNodeData?.log_type?.unit !== "state"  ? `(${renderUnit(foundIoTNodeData?.log_type?.unit)})` : "-"}`)

      let mainSelected = [{
        value: foundIoTNodeData?.type_number,
        label: `${foundIoTNodeData?.log_type?.technical_name || foundIoTNodeData?.log_type?.display_name || foundIoTNodeData?.log_type?.plc_node_id}${(foundIoTNodeData?.log_type?.unit && foundIoTNodeData?.log_type?.unit !== "state") ? ` (${renderUnit(foundIoTNodeData?.log_type?.unit)})` : ""}`,
        state_names: foundIoTNodeData?.log_type?.state_names || []
      }]
      setSelectedOptions(mainSelected || []); // Update the state with the selected options
      fetchDataClustered(mainSelected, desired_start_time || null, desired_end_time || null)
    }

  }, [foundIoTNodeData]);

  function renderUnit(unit) {

    switch (unit) {
      case "°C":
        return i18n.language === "us" ? "°F" : "°C"
      case "bar":
        return i18n.language === "us" ? "PSI" : "bar"
      default:
        return unit
    }
  }

  function reloadData(event) {
    event.preventDefault(); // Prevent the default form submission behavior
    fetchDataClustered(null, null, null)
  }

  const [compareCharts, setCompareCharts] = useState();

  const onFetchData = () => {
    fetchDataClustered(selectedOptions, PeriodStartDate, PeriodEndDate);
  };

  const fetchDataClustered = useCallback(async (selected, start, end) => {

    if (start && end && new Date(start) >= new Date(end)) {
      console.error("Start date is after end date. Aborting data fetch.");
      return;
    }

    setIsLoading(true);

    // Initialize plcNodeIds from selected or fallback to selectedOptions
    const plcNodeIds = selected ?? selectedOptions ?? [];

    // Exit early if plcNodeIds is empty or contains invalid data
    if (plcNodeIds.length === 0 || !plcNodeIds[0]?.value) {
      console.log("Skipping data load: No valid plcNodeIds provided.");
      setIsLoading(false);
      return;
    }

    // Define a function for each request with error handling
    const fetchClusteredData = async ({ value, label }) => {

      console.log(`Step 2) Fetching chart data for ${label}`);

      try {
        const response = await axios.get(`/assets/${asset_id}/asset_logs/clustered`, {
          params: {
            period_start: start || PeriodStartDate,
            period_end: end || PeriodEndDate,
            fill_gaps: showRawData ? "null" : "lv",
            log_type_number: value,
            unit_language: i18n.language === "us" ? "us" : "de",
            desc: false,
          },
        });
        console.log(`Step 3) Received chart data for ${label}`); // response.data 
        return { label, data: response.data, state_names: label.state_names || [] };
      } catch (error) {
        console.error(`Error loading data for ${label} (${value}):`, error);
        setError({ showMessage: true, errorData: error })
        return null;
      }
    };

    // Map and execute requests with Promise.allSettled to handle individual request failures
    const responses = await Promise.allSettled(plcNodeIds?.map(fetchClusteredData));

    // Filter successful responses and map to structured data for charts
    const newChartsData = responses
      .filter(({ status, value }) => status === "fulfilled" && value)
      .map(({ value }) => value);

    if (newChartsData.length > 0) {
      setSubtitle(getSubtitle(newChartsData[0].data));
      setCompareCharts(newChartsData);
    } else {
      console.warn("No valid chart data received.");
    }

    setIsLoading(false);
  });

  // Helper function for setting subtitle
  const getSubtitle = (data) => {
    if (data?.length > 0) {
      const start = parseISO(data[0].interval_start);
      const end = parseISO(data[data.length - 1].interval_start);
      return `${t("Time span")}: ${formatDistance(start, end, { locale: i18n.language === "de" ? de : undefined })}`;
    }
    return `${t("Time span")}: -`;
  };

  const combinedChartData = useMemo(() => {
    if (!compareCharts) return [];
    return compareCharts.map((chart) => ({
      name: chart.label,
      type: "line",
      data: chart.data,
    }));
  }, [compareCharts]);


  useEffect(() => {

    if (showRawData !== undefined) {

      console.log("Show Raw Data Trigger")
      console.log("Changed show raw data")
      fetchDataClustered(null, null, null)
    }

  }, [showRawData]);

  const BooleanBackgroundChart = memo(({ data, state_names, title, selectedTimeZone }) => {
    const palette = usePalette();

    const { t, i18n } = useTranslation();

    // Transform data into series format for ApexCharts
    const seriesData = data?.data?.map(({ interval_start, amount }) => ({
      x: format(
        utcToZonedTime(parseISO(interval_start), selectedTimeZone),
        i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a",
        { timeZone: selectedTimeZone, locale: i18n.language === "de" ? de : enUS }
      ),
      y: amount != null ? amount : 0 // Default to 0 if amount is null
    }));

    const handleZoom = (chartContext, { xaxis }) => {
      const baseEl = chartContext?.w?.globals?.dom?.baseEl;

      // Check if baseEl exists before continuing
      if (!baseEl) {
        console.error("Chart baseEl is not available.");
        return;
      }

      const zoomedRangeMin = xaxis.min;
      const zoomedRangeMax = xaxis.max;

      // Ensure valid zoomed range and data availability
      const start = compareCharts[0]?.data?.[zoomedRangeMin]?.interval_start;
      const end = compareCharts[0]?.data?.[zoomedRangeMax]?.interval_start;

      if (start && end) {
        setPeriodStartDatePicker(parseISO(start));
        setPeriodEndDatePicker(parseISO(end));
        setTimeout(() => fetchDataClustered(null, start, end), 100);
      } else {
        console.error("Invalid data range for zooming or elements not rendered.");
      }
    };

    const getTickAmount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) return 15;
      if (screenWidth > 768) return 7;
      return 5;
    };

    function chosenColor() {
      const firstValidColor = state_names?.find(
        (item) => item.color_coding !== "" && item.color_coding !== "secondary" && item.color_coding !== null
      )?.color_coding;

      // If no valid color_coding is found, default to '#C3E6CB'
      return firstValidColor ? [palette[firstValidColor]] : ['#C3E6CB'];
    }

    const options = {
      chart: {
        type: 'area',
        animations: { enabled: false },
        toolbar: {
          show: true,
          tools: { zoom: true, zoomin: false, zoomout: false, pan: false, reset: false },
        },
        events: {
          zoomed: handleZoom,
        },
      },
      title: {
        text: t(title || ""),
        align: 'left',
        style: { fontSize: '12px', fontWeight: 'bold', color: palette.black },
      },
      xaxis: {
        // type: 'datetime',
        tickAmount: getTickAmount(),
        labels: {
          trim: false,
          show: true,
          align: 'right',
          style: { colors: palette.black },
          // formatter: (val) => {
          //   if (!val) return "";
          //   try {
          //     return format(
          //       utcToZonedTime(val, selectedTimeZone),
          //       i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a",
          //       { locale: i18n.language === "de" ? de : enUS }
          //     );
          //   } catch (error) {
          //     console.error("Date formatting error:", error);
          //     return "";
          //   }
          // },
        },
      },
      yaxis: {
        min: 0,
        max: state_names?.length - 1,
        tickAmount: state_names?.length - 1,
        labels: {
          style: {
            padding: { left: 10 },
            fontWeight: 'bold',
            color: palette.black,
          },
          formatter: (val) => {
            const state = state_names?.find((item) => item.value === val);
            return state ? t(state.display_name) : "";
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            const state = state_names?.find((item) => item.value === y);
            return state ? t(state.display_name) : "";
          },
        },
      },
      fill: {
        type: 'solid',
        colors: chosenColor(), // Use color of the last item in state_names or a default
        opacity: 0.5,
      },
      stroke: {
        curve: 'stepline',
        show: false,
        width: 1,
        colors: chosenColor(), // Line color based on first item
      },
      dataLabels: {
        enabled: false,
      },
    };

    // const options = {
    //   chart: {
    //     type: 'area',
    //     animations: { enabled: false },
    //     toolbar: {
    //       show: true,
    //       tools: { zoom: true, zoomin: false, zoomout: false, pan: false, reset: false },
    //     },
    //     events: { zoomed: handleZoom },
    //   },
    //   yaxis: {
    //     min: 0,
    //     max: state_names.length - 1,
    //     tickAmount: state_names.length - 1,
    //     labels: {
    //       formatter: (val) => {
    //         const state = state_names.find((item) => item.value === val);
    //         return state ? t(state.display_name) : "";
    //       },
    //     },
    //   },
    //   fill: {
    //     colors: state_names.map((item) => palette[item.color_coding || "gray"]),
    //   },
    //   stroke: {
    //     curve: 'stepline',
    //     colors: state_names.map((item) => palette[item.color_coding || "gray"]),
    //   },
    // };

    const series = [{ name: t("State"), data: seriesData }];

    return (
      <Chart options={options} series={series} type="area" height={250} />
    );
  });

  // const UsageChart = memo((props) => {
  //   const palette = usePalette();
  //   const { t, i18n } = useTranslation();

  //   console.log("---> Rendering chart")
  //   if (!props.data || !props.data.length) {
  //     console.warn("No data available in props.data");
  //     return <h6 className="text-muted text-center">{t("No data available.")}</h6>;
  //   }

  //   const data = props?.data || [];

  //   // Extract unique timestamps across all datasets
  //   const allTimestamps = new Set();
  //   data.forEach((dataset) => {
  //     dataset.data.forEach((point) => {
  //       if (point && point.interval_start) {
  //         const date = new Date(point.interval_start);
  //         if (!isNaN(date)) {
  //           allTimestamps.add(date.toISOString());
  //         } else {
  //           console.warn("Invalid date encountered:", point.interval_start);
  //         }
  //       } else {
  //         console.warn("Missing interval_start in point:", point);
  //       }
  //     });
  //   });

  //   // Sort timestamps
  //   const sortedTimestamps = Array.from(allTimestamps)
  //     .map((timestamp) => new Date(timestamp))
  //     .sort((a, b) => a - b);

  //   // Normalize each dataset by filling missing values with `null`
  //   const normalizedData = data.map((dataset) => ({
  //     name: dataset.name,
  //     type: dataset.type,
  //     data: sortedTimestamps.map((timestamp) => {
  //       const point = dataset.data.find(
  //         (p) => p && p.interval_start && new Date(p.interval_start).toISOString() === timestamp.toISOString()
  //       );
  //       return { x: timestamp, y: point ? point.amount : null }; // Use null for missing points
  //     }),
  //   }));

  //   // const data = props?.data?.map(entry => ({
  //   //   name: entry?.name,
  //   //   type: entry?.type,
  //   //   data: entry?.data?.map(d => ({
  //   //     x: parseISO(d.interval_start), // Ensure dates are parsed and consistent
  //   //     y: d.amount !== null ? d.amount : 0 // Assign 0 if amount is null
  //   //   }))
  //   // })) || [];

  //   const handleZoom = (chartContext, { xaxis }) => {
  //     const baseEl = chartContext?.w?.globals?.dom?.baseEl;

  //     // Check if baseEl exists before continuing
  //     if (!baseEl) {
  //       console.error("Chart baseEl is not available.");
  //       return;
  //     }

  //     const zoomedRangeMin = xaxis.min;
  //     const zoomedRangeMax = xaxis.max;

  //     // If zoomedRangeMin or zoomedRangeMax are undefined or null, exit early
  //     if (zoomedRangeMin == null || zoomedRangeMax == null) {
  //       console.error("Zoomed range is not valid.");
  //       return;
  //     }

  //     // Retrieve actual date values based on zoomedRangeMin and zoomedRangeMax
  //     const start = new Date(zoomedRangeMin);
  //     const end = new Date(zoomedRangeMax);

  //     // Ensure that `start` is before `end`
  //     if (start >= end) {
  //       console.error("Zoomed range is invalid: end date is before start date.");
  //       return;
  //     }

  //     // Update date pickers and fetch data for the zoomed range
  //     setPeriodStartDatePicker(start);
  //     setPeriodEndDatePicker(end);

  //     // Fetch data for the new range with a slight delay to ensure synchronization
  //     setTimeout(() => fetchDataClustered(null, start.toISOString(), end.toISOString()), 100);
  //   };

  //   const labels = props.labels || sortedTimestamps.map((date) => date.toISOString());

  //   // Generate discrete markers configuration for each data point
  //   const generateDiscreteMarkers = (data) => {
  //     let discreteMarkers = [];

  //     // console.log("---->", foundIoTNodeData, data)

  //     if (foundIoTNodeData?.upper_limit || foundIoTNodeData?.lower_limit) {
  //       data.forEach((series, seriesIndex) => {
  //         series.data.forEach(({ interval_start, amount }, dataIndex) => {
  //           if (amount > foundIoTNodeData?.upper_limit) {
  //             // console.log("--->", value, foundIoTNodeData?.upper_limit)
  //             discreteMarkers.push({
  //               seriesIndex: seriesIndex,
  //               dataPointIndex: dataIndex,
  //               size: 2,
  //               fillColor: '#FF4560',
  //               strokeColor: '#FF4560',
  //               shape: "circle"
  //             });
  //           } else if (amount < foundIoTNodeData?.lower_limit) {
  //             // console.log("->", value, foundIoTNodeData?.lower_limit)
  //             discreteMarkers.push({
  //               seriesIndex: seriesIndex,
  //               dataPointIndex: dataIndex,
  //               size: 1.5,
  //               fillColor: '#FFA500',
  //               strokeColor: '#FFA500',
  //               shape: "circle"
  //             });
  //           }
  //         });
  //       })
  //     };

  //     // console.log("---->", discreteMarkers.length)
  //     return discreteMarkers;
  //   };

  //   const generateAnnotations = () => {
  //     let annotations = [];

  //     if (foundIoTNodeData?.upper_limit) {

  //       annotations.push({
  //         y: foundIoTNodeData?.upper_limit,
  //         y2: foundIoTNodeData?.upper_limit * 2,
  //         borderColor: '#000',
  //         fillColor: '#FF4560',
  //         opacity: 0.1,
  //         label: {
  //           text: '',
  //           style: {
  //             color: '#fff',
  //             background: '#FF4560'
  //           }
  //         }
  //       }, {
  //         y: foundIoTNodeData?.upper_limit,
  //         borderColor: '#FF4560',
  //         label: {
  //           borderColor: '#FF4560',
  //           style: {
  //             color: '#fff',
  //             background: '#FF4560'
  //           },
  //           text: t("Max. operating limit")
  //         }
  //       });
  //     }

  //     if (foundIoTNodeData?.lower_limit) {

  //       annotations.push({
  //         y: 0,
  //         y2: foundIoTNodeData?.lower_limit,
  //         borderColor: '#000',
  //         fillColor: '#FFA500',
  //         opacity: 0.1,
  //         label: {
  //           text: '',
  //           style: {
  //             color: '#fff',
  //             background: '#FFA500'
  //           }
  //         }
  //       }, {
  //         y: foundIoTNodeData?.lower_limit,
  //         borderColor: '#FFA500',  // Changed to orange
  //         label: {
  //           borderColor: '#FFA500',  // Changed to orange
  //           style: {
  //             color: '#fff',
  //             background: '#FFA500'  // Changed to orange
  //           },
  //           text: t("Min. operating limit")
  //         }
  //       })
  //     }

  //     // console.log("---> All annotations: ", annotations)

  //     return annotations
  //   }

  //   const discreteMarkers = compareCharts?.length === 1 && generateDiscreteMarkers(data);

  //   // Only show annotations if there is one data set
  //   const customAnnotations = data?.length === 1 ? generateAnnotations() : []

  //   const getTickAmount = () => {
  //     const screenWidth = window.innerWidth;
  //     if (screenWidth > 1200) {
  //       return 15; // More ticks for larger screens
  //     } else if (screenWidth > 768) {
  //       return 7; // Medium amount of ticks for tablet screens
  //     } else {
  //       return 5; // Fewer ticks for mobile screens
  //     }
  //   };

  //   const options = {
  //     chart: {
  //       animations: {
  //         enabled: false,
  //       },
  //       toolbar: {
  //         show: false,
  //         tools: {
  //           zoom: true,
  //           zoomin: false,
  //           zoomout: false,
  //           pan: false,
  //           reset: false,
  //         },
  //       },
  //       events: {
  //         zoomed: handleZoom,
  //       },
  //     },
  //     stroke: {
  //       width: [2, 2, 2, 2, 2, 2],
  //     },
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "100%",
  //       },
  //     },
  //     title: {
  //       text: t(props?.title || ""), // Translated chart title
  //       align: 'left', // Aligns the title to the center (can be 'left', 'center', 'right')
  //       style: {
  //         fontSize: '12px', // Font size of the title
  //         fontWeight: 'bold', // Bold font style
  //         color: palette.black, // Color of the title text
  //       },
  //     },
  //     fill: {
  //       gradient: {
  //         inverseColors: false,
  //         shade: "light",
  //         type: "vertical",
  //         opacityFrom: 0.85,
  //         opacityTo: 0.55,
  //         stops: [0, 100, 100, 100],
  //       },
  //     },
  //     labels: labels,
  //     annotations: {
  //       yaxis: customAnnotations
  //     },
  //     markers: {
  //       size: 1.5, // Adjust size for better visibility
  //       // Remove fillColor and strokeColor for regular markers, use series color by default
  //       fillColor: function ({ seriesIndex, w }) {
  //         return w.globals.colors[seriesIndex]; // Use series color for marker fill
  //       },
  //       strokeColor: function ({ seriesIndex, w }) {
  //         return w.globals.colors[seriesIndex]; // Use series color for marker stroke
  //       },
  //       shape: "circle",
  //       discrete: discreteMarkers, // Discrete markers still use the pre-defined fillColor and strokeColor
  //     },
  //     // xaxis: {

  //     //   tickAmount: getTickAmount(),
  //     //   labels: {
  //     //     trim: false,
  //     //     show: true,
  //     //     align: 'right',
  //     //     minWidth: 0,
  //     //     maxWidth: 160,
  //     //     style: {
  //     //       colors: palette.black,
  //     //     },
  //     //     formatter: function (value) {
  //     //       return value?.replace(":00", "");
  //     //     },
  //     //   },
  //     // },
  //     xaxis: {
  //       type: 'datetime', // Ensure the x-axis is interpreted as dates
  //       tickAmount: getTickAmount(),
  //       labels: {
  //         formatter: (value) => {
  //           return format(
  //             utcToZonedTime(new Date(value), selectedTimeZone),
  //             i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a",
  //             { locale: i18n.language === "de" ? de : enUS }
  //           );
  //         },
  //         style: { colors: palette.black },
  //         trim: false,
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160
  //         // other configurations...
  //       }
  //     },
  //     yaxis: {
  //       labels: {
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160,
  //         style: {
  //           colors: palette.black,
  //         },
  //         formatter: (value) => value.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 }),
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       labels: {
  //         colors: palette.black,
  //         useSeriesColors: false
  //       },
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //       y: {
  //         formatter: function (val) {
  //           return val?.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 });
  //         },
  //       },
  //     },
  //     // Use the same colors as markers for the lines
  //     colors: [
  //       palette.info,
  //       palette.success,
  //       palette.warning,
  //       palette.primary,
  //       palette.danger,
  //     ],
  //   }

  //   return (<div>
  //     {
  //       props?.data.length === 0

  //         ?

  //         <h6 className="card-subtitle text-muted">
  //           {t("No data available.")}
  //         </h6>

  //         :

  //         <div className="chart">
  //           <Chart
  //             options={options}
  //             series={normalizedData}
  //             type="line"
  //             height="340"
  //           />
  //         </div>
  //     }
  //   </div>
  //   );
  // });

  const UsageChart = memo(({ data, title, labels, selectedTimeZone, upperLimit, lowerLimit }) => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    console.log("---> Rendering chart");

    // Memoize derived data unconditionally
    const normalizedData = useMemo(() => {
      if (!data || !data.length) return [];
      const allTimestamps = new Set();

      data.forEach((dataset) =>
        dataset.data.forEach((point) => {
          if (point?.interval_start) allTimestamps.add(new Date(point.interval_start).toISOString());
        })
      );

      const sortedTimestamps = Array.from(allTimestamps)
        .map((timestamp) => new Date(timestamp))
        .sort((a, b) => a - b);

      return data.map((dataset) => ({
        name: dataset.name,
        type: dataset.type,
        data: sortedTimestamps.map((timestamp) => {
          const point = dataset.data.find((p) => p?.interval_start === timestamp.toISOString());
          return { x: timestamp, y: point ? point.amount : null };
        }),
      }));
    }, [data]);

    // Generate annotations
    const generateAnnotations = useCallback(() => {
      const annotations = [];

      if (upperLimit !== undefined) {
        annotations.push({
          y: upperLimit,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560',
            },
            text: t('Max. operating limit'),
          },
        });
      }

      if (lowerLimit !== undefined) {
        annotations.push({
          y: lowerLimit,
          borderColor: '#FFA500',
          label: {
            borderColor: '#FFA500',
            style: {
              color: '#fff',
              background: '#FFA500',
            },
            text: t('Min. operating limit'),
          },
        });
      }

      return annotations;
    }, [upperLimit, lowerLimit, t]);

    const getTickAmount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) return 15;
      if (screenWidth > 768) return 7;
      return 5;
    };

    const handleZoom = (chartContext, { xaxis }) => {
      const baseEl = chartContext?.w?.globals?.dom?.baseEl;

      // Check if baseEl exists before continuing
      if (!baseEl) {
        console.error("Chart baseEl is not available.");
        return;
      }

      const zoomedRangeMin = xaxis.min;
      const zoomedRangeMax = xaxis.max;

      // If zoomedRangeMin or zoomedRangeMax are undefined or null, exit early
      if (zoomedRangeMin == null || zoomedRangeMax == null) {
        console.error("Zoomed range is not valid.");
        return;
      }

      // Retrieve actual date values based on zoomedRangeMin and zoomedRangeMax
      const start = new Date(zoomedRangeMin);
      const end = new Date(zoomedRangeMax);

      // Ensure that `start` is before `end`
      if (start >= end) {
        console.error("Zoomed range is invalid: end date is before start date.");
        return;
      }

      // Update date pickers and fetch data for the zoomed range
      const startFormatted = toDateTimeLocalString(start);
      const endFormatted = toDateTimeLocalString(end);

      handleChangeStartDatePicker(startFormatted);
      handleChangeEndDatePicker(endFormatted);

      // Fetch data using ISO string format for the backend
      fetchDataClustered(null, start.toISOString(), end.toISOString());
    };

    const generateDiscreteMarkers = (data) => {
      let discreteMarkers = [];

      // console.log("---->", foundIoTNodeData, data)

      if (foundIoTNodeData?.upper_limit || foundIoTNodeData?.lower_limit) {
        data.forEach((series, seriesIndex) => {
          series.data.forEach(({ interval_start, amount }, dataIndex) => {
            if (amount > foundIoTNodeData?.upper_limit) {
              // console.log("--->", value, foundIoTNodeData?.upper_limit)
              discreteMarkers.push({
                seriesIndex: seriesIndex,
                dataPointIndex: dataIndex,
                size: 2,
                fillColor: '#FF4560',
                strokeColor: '#FF4560',
                shape: "circle"
              });
            } else if (amount < foundIoTNodeData?.lower_limit) {
              // console.log("->", value, foundIoTNodeData?.lower_limit)
              discreteMarkers.push({
                seriesIndex: seriesIndex,
                dataPointIndex: dataIndex,
                size: 1.5,
                fillColor: '#FFA500',
                strokeColor: '#FFA500',
                shape: "circle"
              });
            }
          });
        })
      };

      // console.log("---->", discreteMarkers.length)
      return discreteMarkers;
    };

    const customAnnotations = compareCharts?.length === 1 ? generateAnnotations() : []
    const discreteMarkers = compareCharts?.length === 1 && generateDiscreteMarkers(data);

    // Define chart options unconditionally
    const options = useMemo(() => ({
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            zoom: true,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        events: {
          zoomed: handleZoom,
        },
      },
      stroke: {
        width: [2, 2, 2, 2, 2, 2],
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
        },
      },
      title: {
        text: t(title || ""), // Translated chart title
        align: 'left', // Aligns the title to the center (can be 'left', 'center', 'right')
        style: {
          fontSize: '12px', // Font size of the title
          fontWeight: 'bold', // Bold font style
          color: palette.black, // Color of the title text
        },
      },
      fill: {
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,
      annotations: {
        yaxis: customAnnotations
      },
      markers: {
        size: 1.5, // Adjust size for better visibility
        // Remove fillColor and strokeColor for regular markers, use series color by default
        fillColor: function ({ seriesIndex, w }) {
          return w.globals.colors[seriesIndex]; // Use series color for marker fill
        },
        strokeColor: function ({ seriesIndex, w }) {
          return w.globals.colors[seriesIndex]; // Use series color for marker stroke
        },
        shape: "circle",
        discrete: discreteMarkers, // Discrete markers still use the pre-defined fillColor and strokeColor
      },
      // xaxis: {

      //   tickAmount: getTickAmount(),
      //   labels: {
      //     trim: false,
      //     show: true,
      //     align: 'right',
      //     minWidth: 0,
      //     maxWidth: 160,
      //     style: {
      //       colors: palette.black,
      //     },
      //     formatter: function (value) {
      //       return value?.replace(":00", "");
      //     },
      //   },
      // },
      xaxis: {
        type: 'datetime', // Ensure the x-axis is interpreted as dates
        tickAmount: getTickAmount(),
        labels: {
          formatter: (value) => {
            return format(
              utcToZonedTime(new Date(value), selectedTimeZone),
              i18n.language === "de" ? "d MMM, HH:mm:ss" : "d MMM, hh:mm:ss a",
              { locale: i18n.language === "de" ? de : enUS }
            );
          },
          style: { colors: palette.black },
          trim: false,
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160
          // other configurations...
        }
      },
      yaxis: {
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => value.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 }),
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (val) {
            return val?.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 });
          },
        },
      },
      // Use the same colors as markers for the lines
      colors: [
        palette.info,
        palette.success,
        palette.warning,
        palette.primary,
        palette.danger,
      ],
    }), [palette, generateAnnotations]);

    // Render fallback if no data
    if (!normalizedData.length) {
      return <h6 className="text-muted text-center">{t("No data available.")}</h6>;
    }

    return (
      <Chart options={options} series={normalizedData} type="line" height="340" />
    );
  });

  // const [fullAssetLogs, setFullAssetLogs] = useState(true)

  // setFullAssetLogs
  function getFullAssetLogsDataAndExport(e) {
    e.preventDefault()

    const logTypeNumbers = selectedOptions.map(opt => opt.value); // Extract values as an array of integers
    // Serialize log_type_numbers manually
    const params = {
      period_start: PeriodStartDate,
      period_end: PeriodEndDate,
      limit: 10000,
    };

    const serializedParams = new URLSearchParams(params); // Start with base params
    logTypeNumbers.forEach(num => serializedParams.append('log_type_numbers', num));


    axios
      .get(`/assets/${asset_id}/asset_logs`, {
        params: serializedParams, // Pass the serialized parameters
        paramsSerializer: () => serializedParams.toString(), // Ensure proper serialization
      })
      .then(function (response) {
        // handle success
        console.log(response.status, `/asset_logs. Amount: ${response.data?.length}`, response.data);
        exportCSV(response.data);
      })
      .catch(function (error) {
        // handle error
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  }

  function exportCSV(data) {
    if (!data || data.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    // Map data to the desired CSV structure
    const csvData = data.map(item => ({
      time: item.time,
      name: item?.technical_name || item?.display_name || "", // Handle missing technical_name
      value: item.value.toFixed(4).replace(".", ","),
      plc_node_id: item.plc_node_id,
      unit: item.unit || "", // Handle missing unit
    }));

    // Define the columns for the CSV
    const fields = ["time", "name", "value", "plc_node_id", "unit"];

    // Generate and download the CSV file
    try {
      const csvContent = json2csv.parse(csvData, { fields, delimiter: ";" });
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Export_${data[0]?.technical_name || asset_data?.title}_${new Date().toISOString()}.csv`; // Dynamic filename
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  }

  // function exportCSV(data) {

  //   // Check if data is available
  //   if (!compareCharts || compareCharts.length === 0) {
  //     console.warn("No data available for export.");
  //     return;
  //   }

  //   // Create a unique set of timestamps
  //   const allTimestamps = new Set();
  //   compareCharts.forEach((chart) => {
  //     chart.data.forEach((item) => {
  //       if (item.interval_start) {
  //         allTimestamps.add(item.interval_start);
  //       }
  //     });
  //   });

  //   // Convert the timestamps set to an array and sort
  //   const sortedTimestamps = Array.from(allTimestamps).sort();

  //   // Initialize the CSV structure
  //   const csvData = sortedTimestamps.map((timestamp) => {
  //     const row = { time: timestamp };
  //     compareCharts.forEach((chart) => {
  //       // Find the data point for this timestamp
  //       const dataPoint = chart.data.find(
  //         (item) => item.interval_start === timestamp
  //       );
  //       row[chart.label] = dataPoint ? dataPoint.amount : null; // Add the metric value or null if missing
  //     });
  //     return row;
  //   });

  //   // Define columns for CSV
  //   const columns = ["time", ...compareCharts.map((chart) => chart.label)];

  //   // Generate and download CSV
  //   try {
  //     const csvContent = json2csv.parse(csvData, { fields: columns, delimiter: ";" });
  //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = `Export_${asset_data?.title}_${new Date().toISOString()}.csv`; // Dynamic filename
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error generating CSV:", error);
  //   }
  // }

  const downloadFile = (datasets, format, allFields) => {
    if (format === 'csv') {
      let combinedData = [];

      // Iterate over each dataset
      datasets.forEach((dataset, index) => {
        const fields = Array.isArray(allFields[index]) ? allFields[index] : [];
        dataset.data.forEach(item => {
          let entry = { name: dataset.name };
          // Use fields to map values from the item
          fields.forEach(field => {
            entry[field] = item[field];
          });
          combinedData.push(entry);
        });
      });

      // Parse the combined data into CSV
      try {
        const csvData = json2csv.parse(combinedData, { fields: allFields.flat(), delimiter: ';' });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `combined_data_export.csv`;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating CSV:', error);
      }
    }
  };

  const { theme } = useTheme();

  const isDarkMode = (theme === "dark")

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#333' : 'white',
      color: isDarkMode ? '#f5f5f5' : '#000',
      borderColor: isDarkMode ? '#444' : '#ddd',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? '#f5f5f5' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#aaa' : '#666', // lighter placeholder color for dark mode
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#333' : 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? (isDarkMode ? '#444' : '#e2e2e2') : 'transparent',
      color: isDarkMode ? '#f5f5f5' : '#000',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkMode ? '#f5f5f5' : '#000',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isDarkMode ? '#f5f5f5' : '#666',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#555' : '#ddd',
    }),
  };

  // useEffect(() => {
  //   if (selectedOptions.length > 0 && PeriodStartDatePicker && PeriodEndDatePicker) {
  //     fetchDataClustered(selectedOptions, PeriodStartDatePicker, PeriodEndDatePicker);
  //   }
  // }, [selectedOptions, PeriodStartDatePicker, PeriodEndDatePicker]);

  const [PeriodStartDate, setPeriodStartDate] = useState(desired_start_time || startDate);
  const [PeriodEndDate, setPeriodEndDate] = useState(desired_end_time || now);

  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();

  useEffect(() => {
    const defaultStart = desired_start_time ? new Date(desired_start_time) : startDate;
    const defaultEnd = desired_end_time ? new Date(desired_end_time) : now;

    setPeriodStartDatePicker(toDateTimeLocalString(defaultStart));
    setPeriodStartDate(defaultStart.toISOString());

    setPeriodEndDatePicker(toDateTimeLocalString(defaultEnd));
    setPeriodEndDate(defaultEnd.toISOString());
  }, [desired_start_time, desired_end_time]);

  const toDateTimeLocalString = (date) => {
    return date.toISOString().slice(0, 16); // Trim to match `datetime-local` format
  };

  function handleChangeStartDatePicker(dateISOString) {
    const newStartDate = new Date(dateISOString);
    if (newStartDate >= new Date(PeriodEndDate)) {
      console.error("Start date cannot be after end date");
      return;
    }
    setPeriodStartDatePicker(dateISOString);
    setPeriodStartDate(newStartDate.toISOString());
  }

  function handleChangeEndDatePicker(dateISOString) {
    const newEndDate = new Date(dateISOString);
    if (newEndDate <= new Date(PeriodStartDate)) {
      console.error("End date cannot be before start date");
      return;
    }
    setPeriodEndDatePicker(dateISOString);
    setPeriodEndDate(newEndDate.toISOString());
  }

  return (
    <Modal size="xl" show
      style={{ zIndex: 1050 }} // Higher z-index to ensure it appears above the first modal
      onHide={handleClose} >
      <Modal.Header closeButton>{t("Data View")}</Modal.Header>
      <Modal.Body style={{ backgroundColor: palette["gray-100"], padding: 20 }}>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="flex-fill">
              <Card.Body>
                <Row>
                  <Col md="3">
                    <Form.Group className="mb-1">
                      <Form.Label>{t("Start date")}</Form.Label>
                      <Form.Control
                        value={PeriodStartDatePicker}
                        onChange={(event) => handleChangeStartDatePicker(event.target.value)}
                        type="datetime-local"
                        max={PeriodEndDatePicker} // Restrict to the value of the end date
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-1">
                      <Form.Label>{t("End date")}</Form.Label>
                      <Form.Control
                        value={PeriodEndDatePicker}
                        onChange={(event) => handleChangeEndDatePicker(event.target.value)}
                        type="datetime-local"
                        min={PeriodStartDatePicker} // Restrict to the value of the end date
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" className="d-flex align-items-center justify-content-end">
                    {compareCharts?.length > 1 && (
                      <Form.Check
                        className="me-3 mt-2"
                        checked={showInOneChart}
                        onChange={() => setShowInOneChart(!showInOneChart)}
                        label={t("Show in one chart")}
                        type="checkbox"
                      />
                    )}
                    <Form.Check
                      className="me-1 mt-2"
                      type="checkbox"
                      onChange={() => setShowRawData(!showRawData)}
                      defaultChecked={showRawData}
                      label={t("Raw data")}
                    />
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Popover>
                          <Popover.Header as="h3">Info</Popover.Header>
                          <Popover.Body>
                            {t(
                              "Gaps do not necessarily indicate missing data. They usually indicate that a value did not change within an interval, since only value changes are being reported and displayed for performance reasons."
                            )}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <HelpCircle className="feather me-3 mt-2" />
                    </OverlayTrigger>

                    {/* <Button variant="outline-secondary" className="shadow-sm me-1 mt-2" onClick={() => setReset(!reset)}>
                        {t("Reset")}
                      </Button> */}
                    {selectedOptions?.length === 1 && <Button variant="outline-secondary" className="shadow-sm me-1 mt-2" onClick={getFullAssetLogsDataAndExport}>
                      {t("Export CSV")}
                    </Button>}
                    <Button
                      className="me-1 mt-2"
                      // type="submit"
                      onClick={onFetchData}
                      variant="primary">
                      <RefreshCw className="feather me-1" /> {t("Fetch")}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="mb-3">
              <Form.Label>{t("Metrics")}</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={IoTNodesForComparison}
                isMulti
                styles={customStyles}
                placeholder={t("Select Metric")}
                value={selectedOptions[0]?.value ? selectedOptions : null} // Set the value to the selected options
                onChange={handleSelectChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {
          isLoading
            ? <LoadingSpinner withText={"Loading"} small />
            :
            <Row>
              <Col md="12">
                <div>
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Row>
                        <Col xs="auto" className="d-sm-block">
                          <Card.Title tag="h5">{t(asset_data?.title)}{asset_data?.machine_model ? ` (${t(asset_data?.machine_model)})` : ""}</Card.Title>
                          <h6>{subtitle}</h6>
                        </Col>
                        <Col xs="auto" className="ms-auto text-end">
                          <div className="d-flex align-items-center flex-wrap">
                            <Form.Select
                              className="form-select-sm me-2 mt-1"
                              style={{ width: "200px" }}
                              value={selectedTimeZone}
                              onChange={(event) => handleChangeTimeZone(event)}
                            >
                              <option key={"browser"} value={browserTimeZone}>
                                {t("Your time") + " (" + browserTimeZone + ")"}
                              </option>
                              <option
                                key={"asset_local"}
                                disabled={!asset_data?.timezone}
                                value={asset_data?.timezone}
                              >
                                {t("Asset time") +
                                  " (" +
                                  (asset_data?.timezone || "Bitte beim Asset spezifizieren") +
                                  ")"}
                              </option>
                            </Form.Select>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(28 * 24)}>{t("Last 28d")}</Button>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(7 * 24)}>{t("7d")}</Button>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(24)}>{t("24h")}</Button>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(6)}>{t("6h")}</Button>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(1)}>{t("1h")}</Button>
                            <Button size="sm" variant="outline-primary me-1 mt-1" onClick={() => handleChangeDates(0.083)}>{t("5m")}</Button>
                            {/* <Button
                  onClick={() => downloadFile(combinedChartData, 'csv', [['interval_start', 'amount'], ['interval_start', 'amount']])}
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm mt-1"
                >
                  <Download className="feather" /> Download .csv
                </Button> */}
                          </div>

                        </Col>
                      </Row>
                    </Card.Header>
                    {
                      showInOneChart
                        ? <Card.Body>
                          {/* {
                            compareCharts?.length === 1 && foundIoTNodeData?.log_type?.state_names?.length > 0
                              ? <BooleanBackgroundChart
                                data={compareCharts[0]}
                                state_names={foundIoTNodeData?.log_type?.state_names}
                                title={foundIoTNodeData?.log_type?.display_name || ""}
                                labels={compareCharts[0]?.data?.map((d) => d.x) || []}
                                selectedTimeZone={selectedTimeZone}
                              />

                              : */}
                          <UsageChart
                            title=""
                            labels={combinedChartData?.[0]?.data?.map((d) => d.x) || []} // Generate labels from data
                            upperLimit={foundIoTNodeData?.upper_limit}
                            lowerLimit={foundIoTNodeData?.lower_limit}
                            selectedTimeZone={selectedTimeZone}
                            format={"decimal"}
                            data={combinedChartData}
                          />
                          {/* } */}
                        </Card.Body>
                        : <Card.Body>
                          {compareCharts?.map((item, indx) => {

                            // if (item?.state_names?.length > 0) {
                            //   return <BooleanBackgroundChart
                            //     key={indx}
                            //     data={item.data}
                            //     title={item?.label}
                            //     state_names={item?.state_names}
                            //   />
                            // } else {

                            return <UsageChart key={indx}
                              max={Math.round(Math.max(...item?.data?.map(o => o.amount)) * 1.10)}
                              format={"decimal"}
                              selectedTimeZone={selectedTimeZone}
                              labels={item?.data?.map((d) => d.x) || []} // Generate labels from data
                              title={item?.label}
                              data={[{
                                name: t(`${item?.label}`),
                                type: "line",
                                data: item?.data
                              }
                              ]}
                            />
                            // }

                          })}
                        </Card.Body>
                    }
                  </Card>
                </div>
              </Col>
            </Row>
        }

        {compareCharts?.length === 1 && <Row>
          <Col>
            <WatchTowerList slim showAddButton watchtowers={foundIoTNodeData?.watchtowers} selected_asset_id={asset_data?.id} selected_iot_node_id={foundIoTNodeData?.id} />
          </Col>
        </Row>}

        <hr />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="primary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default AssetDigitalTwinChartModal;