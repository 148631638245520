import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import { Plus } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../config";

const AddContractModal = (props) => {
  const navigate = useNavigate();
  // let { id } = useParams();
  const [data, setData] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [ManufacturerArray, setManufacturerArray] = useState([]);

  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [client, setClient] = useState(props?.id);
  const [investor, setInvestor] = useState();
  const [manufacturer, setManufacturer] = useState();
  const [contractType, setContractType] = useState();
  const [ContractTypeOptions, setContractTypeOptions] = useState([]);
  const [contractNumberCustom, setContractNumberCustom] = useState();


  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird
    axios
      .post("/contracts/", {
        title: title,
        starting_date: start,
        end_date: end,
        description: description,
        client_id: client,
        investor_id: investor,
        manufacturer_id: manufacturer,
        contract_type: contractType,
        contract_number_set: contractNumberCustom,
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/contracts");
        navigate("/contracts/" + response.data.id);

        notyf.open({
          type: "success",
          message: t("New item \"") + title + t("\" has been added."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  useEffect(() => {

    if (openModal) {
      axios
        .get("/clients/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/clients");
          setData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/investors/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/investors");
          setInvestors(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/manufacturers")
        .then(function (response) {
          // handle success
          console.log(response.status, "/manufacturers");
          setManufacturerArray(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal]);

  const loadClients = () => {
    return data?.map((client) => {
      return (
        <option key={client.id} value={client.id} selected={client.id === props?.id}>{client.business_name}</option>
      );
    });
  };

  const loadInvestors = () => {
    return investors?.map((investor) => {
      return (
        <option key={investor.id} value={investor.id}>{investor.name}</option>
      );
    });
  };

  const loadManufaturers = () => {
    return ManufacturerArray.map((manufacturer) => {
      return (
        <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.name}</option>
      );
    });
  };

  useEffect(() => {
    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi.json");
        setContractTypeOptions(response.data["components"]["schemas"]["ContractType"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  const ShowContractTypes = () => {
    return ContractTypeOptions?.map((option) => {
      return (
        <option key={option} value={option} selected={option === contractType}>{t(option)}</option>
      );
    });
  };

  const ButtonForType = () => {
    switch (props?.type) {
      case "link":
        return (<Link to="" className="pt-2 mb-2" size="" onClick={() => toggle()}>
          {t("New contract")}
        </Link>)
      default:
        return (<Button
          onClick={() => toggle()}
          variant="primary"
          className="shadow-sm me-1">
          <Plus className="feather" /> {props?.id === undefined ? t("New") : t("New contract")}
        </Button>)
    }
  }

  return (
    <>
      <ButtonForType />
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create contract")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>

            <h4>{t("Contract details")}</h4>
            <br />

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    placeholder={t("ABC Contract")}
                    required
                    onChange={e => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    placeholder={t("Description")}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract start date")}</Form.Label>
                  <Form.Control type="date" onChange={e => setStart(parseISO(e.target.value))} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract end date")}</Form.Label>
                  <Form.Control type="date" onChange={e => setEnd(parseISO(e.target.value))} required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract Type")}</Form.Label>
                  <Form.Select name="state" required onChange={(e) => setContractType(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    <ShowContractTypes />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract Number")}</Form.Label>
                  <Form.Control
                    placeholder={t("123/456/789")}
                    onChange={e => setContractNumberCustom(e.target.value)}
                  />
                </Form.Group>
              </Col>

            </Row>
            <br /><br />
            <hr />
            <h4>{t("Asset-as-a-Service Ecosystem")}</h4>
            <br />
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Client")} / {t("Operator")}</Form.Label>
                  <Form.Select name="state" required onChange={e => setClient(e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadClients()}
                  </Form.Select>
                  <Link to={"/clients"} >{t("Add client")}</Link>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Manufacturer")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setManufacturer(e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadManufaturers()}
                    {/* <option onSelect={() => navigate("/manufacturers")}>{t("-- Add new manufacturer --")}</option> */}
                  </Form.Select>
                  <Link to={"/manufacturers"} >{t("Add manufacturer")}</Link>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Investors")}</Form.Label>
                  <Form.Select name="state" onChange={e => setInvestor(e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadInvestors()}
                    {/* <option onChange={() => navigate("/investors")}>{t("-- Add new investor --")}</option> */}
                  </Form.Select>
                  <Link to={"/investors"} >{t("Add investor")}</Link>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddContractModal;
