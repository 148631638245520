import React, { useContext, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Plus, Trash, ChevronRight, ChevronDown, Clipboard } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";

const GatewayOPCUANodeTreeRenderer = ({ nodes, loadMoreNodes, configData, setShowDataPointModal, handleDelete }) => {
  const { t } = useTranslation();
  const [expandedNodes, setExpandedNodes] = useState({});
  const [loadingNodes, setLoadingNodes] = useState({});
  const notyf = useContext(NotyfContext);

  const TriangleToggle = ({ expanded, onClick }) => (
    <span
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginRight: "8px",
      }}
    >
      {expanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
    </span>
  );

  function copyToClipboard(command, notificationMessage) {

    navigator.clipboard.writeText(command)

    notyf.open({
      type: "success",
      message: notificationMessage,
      duration: "6000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  };

  const toggleNode = async (nodeId, path, subnodes) => {
    if (expandedNodes[nodeId]) {
      setExpandedNodes((prev) => ({ ...prev, [nodeId]: false }));
    } else {
      if (!expandedNodes[`${nodeId}_children`]) {
        if (subnodes && subnodes.length > 0) {
          setExpandedNodes((prev) => ({
            ...prev,
            [nodeId]: true,
            [`${nodeId}_children`]: subnodes,
          }));
        } else {
          setLoadingNodes((prev) => ({ ...prev, [nodeId]: true }));
          try {
            const fetchedNodes = await loadMoreNodes(path, nodeId);
            setExpandedNodes((prev) => ({
              ...prev,
              [nodeId]: true,
              [`${nodeId}_children`]: fetchedNodes,
            }));
          } catch (error) {
            console.error("Error fetching nodes:", error);
          } finally {
            setLoadingNodes((prev) => ({ ...prev, [nodeId]: false }));
          }
        }
      } else {
        setExpandedNodes((prev) => ({ ...prev, [nodeId]: true }));
      }
    }
  };

  const renderNodes = (nodes, level = 0) => (
    <ul style={{ listStyle: "none", paddingLeft: level === 0 ? "0" : "20px" }}>
      {nodes.map((node) => (
        <li key={node.plc_node_id} style={{ marginBottom: "8px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {(node.subnodes?.length > 0 || node.amount_subnodes > 0) && (
              <TriangleToggle
                expanded={expandedNodes[node.plc_node_id]}
                onClick={() =>
                  toggleNode(node.plc_node_id, node.path, node.subnodes)
                }
              />
            )}
            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
              {node.display_name || "-"}
            </span>
            <span style={{ color: "#6c757d", fontSize: "0.9em", flexGrow: 1 }}>
              {node.plc_node_id || "-"}
            </span>
            <span style={{ color: "#6c757d", fontSize: "0.9em", marginRight: "8px" }}>
              {node.data_type && <code>{node.data_type}</code>}
            </span>
            <span style={{ marginRight: "8px" }}>{node.example_value && node.example_value !== "None" ? node.example_value : ""}</span>

            {!node.data_type ? <></> : configData?.items?.some((item) => item.log_type?.plc_node_id === node.plc_node_id) ? (
              <Button
                variant="outline-danger"
                size="sm"
                className="ms-2"
                onClick={(e) => handleDelete(e, node.plc_node_id)}
              >
                <Trash className="feather" /> {t("Remove")}
              </Button>
            ) : (
              <Button
                variant="success"
                size="sm"
                className="ms-2"
                onClick={() => setShowDataPointModal(node)}
              >
                <Plus className="feather" /> {t("Add")}
              </Button>
            )}
            <Button
              variant="outline-primary"
              size="sm"
              className="ms-2"
              style={{ marginRight: "8px" }}
              onClick={() => copyToClipboard(node.plc_node_id, t("Copied!"))}
            >
              <Clipboard className="feather" /> {t("ID")}
            </Button>
          </div>
          {expandedNodes[node.plc_node_id] && (
            <div style={{ marginTop: "8px", paddingLeft: "20px" }}>
              {loadingNodes[node.plc_node_id] ? (
                <Spinner animation="border" size="sm" />
              ) : (
                renderNodes(expandedNodes[`${node.plc_node_id}_children`] || [], level + 1)
              )}
            </div>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div style={{ padding: "16px", border: "1px solid #dee2e6", borderRadius: "8px" }}>
      <h5 style={{ marginBottom: "16px" }}>{t("OPC UA Browser")}</h5>
      {nodes && nodes.length > 0 ? (
        renderNodes(nodes)
      ) : (
        <p style={{ textAlign: "center", color: "#6c757d" }}>{t("No nodes available.")}</p>
      )}
    </div>
  );
};

export default GatewayOPCUANodeTreeRenderer;