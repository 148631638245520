import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Dropdown, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Filter } from "react-feather";
import { useTranslation } from "react-i18next";
import { format, parseISO, isToday, formatDistanceToNow, isYesterday } from 'date-fns'
import { de } from "date-fns/locale"
import ErrorHandler from "../Helper/ErrorHandler";
import AddUserModal from "./UserModal_Add";
import { getIAMBaseURL } from "../../config";

const Users = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { t, i18n } = useTranslation();
    const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

    // When retrieving stored data.
    // let userRole = UserDataManager.getInstance().getUserRole();
    // console.log(userRole);

    function getStatus(status) {
        switch (status) {
            case "approved":
                return t("Approved")
            case "active":
                return t("Active")
            case "request":
                return t("Request")
            case "inactive":
                return t("Inactive")
            case "draft":
                return t("Draft")
            case "initial":
                return t("Initial")
            default:
                return t(status)
        }
    }

    useEffect(() => {
        axios
            .get(getIAMBaseURL() + "/users/", {
                params: {
                    limit: 100
                }
            })
            .then(function (response) {
                // handle success
                console.log(response.status, "/users")
                // setData(response.data.sort((a, b) => a.first_name > b.first_name))
                // setFilteredData(response.data.sort((a, b) => a.first_name > b.first_name))

                setData(response.data?.sort((a, b) => {
                    const dateA = new Date(a?.last_login).getTime();
                    const dateB = new Date(b?.last_login).getTime();
                    return dateB - dateA; // Ascending order
                }))
                setFilteredData(response.data?.sort((a, b) => {
                    const dateA = new Date(a?.last_login).getTime();
                    const dateB = new Date(b?.last_login).getTime();
                    return dateB - dateA; // Ascending order
                }));
            })
            .catch(function (error) {
                // handle error
                setError({ showMessage: true, errorData: error })
                console.log(error);
            });
    }, []);

    const recentlyActive = (last_login) => {
        if (isToday(parseISO(last_login)) === true || isYesterday(parseISO(last_login))) {
            return true
        } else {
            return false
        }
    }

    const loadAvatar = (url) => {
        return (
            <img
                src={url == null ? null : url}
                className="center rounded-circle"
                alt="avatar"
                width={28} height={28}
            />);
    };

    const renderTable = () => {
        return filteredData?.map((user) => {
            return (
                <tr key={user.id} onClick={() => navigate("/users/" + user.id)} style={{ cursor: "pointer" }}>
                    <td>
                        <span className={user.status === "active" || user.status === "approved" ? "badge bg-success" : "badge bg-warning"}>{getStatus(user.status)}</span>
                    </td>
                    <td >{user == null ? null : loadAvatar(user.avatar_url)}</td>
                    <td><strong>{user.first_name} {user.last_name}</strong></td>
                    {/* <td></td> */}
                    <td>{user.position}</td>
                    {/* <td>{user.email}</td> */}
                    <td>{user?.role?.toUpperCase()}</td>
                    {/* <td>{user.phone_number}</td> */}
                    {/* <td>{user.preferred_language}</td> */}
                    <td>{user.last_login === null ? "-" : format(parseISO(user.last_login), "dd MMMM HH:mm", { locale: i18n.language === "de" ? de : null }) + " (" + formatDistanceToNow(parseISO(user.last_login), { locale: i18n.language === "de" ? de : null, addSuffix: true }) + ")"}</td>
                    <td>
                        <span className={user.last_login === null ? "-" : recentlyActive(user.last_login) ? "badge bg-success" : "badge bg-warning"}>{user.last_login === null ? "-" : recentlyActive(user.last_login) ? t("Yes") : t("No")}</span>
                    </td>
                </tr>
            )
        })
    }

    function filterBy(role, newButtonTitle) {

        if (role === "all") {
            setFilteredData(data)
        } else {
            setFilteredData(
                data
                    ?.filter(item => item?.role === role)
                    ?.sort((a, b) => {
                        const dateA = new Date(a?.last_login).getTime();
                        const dateB = new Date(b?.last_login).getTime();
                        return dateB - dateA; // Ascending order
                    })
            );
        }

        setFilterButtonTitle(newButtonTitle)
    }

    const [filterButtonTitle, setFilterButtonTitle] = useState("Filter");

    return (
        <React.Fragment>
            <Helmet title="User" />
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-3">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h3>{t("User")}</h3>
                    </Col>
                    <Col xs="auto" className="ms-auto text-end mt-n1">

                        <Dropdown align={{ lg: 'end' }} className="d-inline">
                            <Dropdown.Toggle variant="light" className="bg-white shadow-sm me-1">
                                <Filter className="feather align-middle" /> {t(filterButtonTitle)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => filterBy("admin", "Filtered: Admin")}>{t("cap-on Admin")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("cap-on agent", "Filtered: cap-on Agent")}>{t("cap-on agent")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("regular", "Filtered: Regular")}>{t("Regular")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("device", "Filtered: Devices")}>{t("Devices")}</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => filterBy("all", "Filter")}>{t("All")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <AddUserModal />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ErrorHandler error={error} />
                        <Card>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th scope="col">{t("Status")}</th>
                                        <th scope="col">{t("")}</th>
                                        <th scope="col">{t("Name")}</th>
                                        {/* <th scope="col">{t("Last name")}</th> */}
                                        <th scope="col">{t("Position")}</th>
                                        {/* <th scope="col">{t("Email")}</th> */}
                                        <th scope="col">{t("Role")}</th>
                                        {/* <th scope="col">{t("Phone Number")}</th> */}
                                        {/* <th scope="col">{t("Language")}</th> */}
                                        <th scope="col">{t("Last login")}</th>
                                        <th scope="col">{t("Recently active")}</th>
                                    </tr>
                                </thead>
                                <tbody>{renderTable()}</tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment >
    );
};

export default Users;
