import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Dropdown, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { ArrowUpRight } from "react-feather";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";

const GatewayJobOptionsModal = ({ handleClose, gateway_data }) => {
  const notyf = useContext(NotyfContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const handleActivate = (e, job_option_id) => {
    e.preventDefault();

    axios
      .post(`/iot_gateways/${gateway_data?.id}/job_options/${job_option_id}/activate`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/activated JOB");

        notyf.open({
          type: "success",
          message: t("This will take around 20s to take effect"),
          duration: "6000",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        // TODO REFRESH

        console.log("refreeeesh here!!")

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

  };

  return (
    <Modal size="xl" show onHide={handleClose}>
      <Modal.Header closeButton>{t("Heartbeat Jobs")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Description")}</th>
                  <th scope="col">{t("Last Activation")}</th>
                  <th scope="col">{t("Successful Last Activation")}</th>
                  <th scope="col">{t("Last User Activation")}</th>
                </tr>
              </thead>
              <tbody>{gateway_data?.job_options.map((entry) => {
                return (
                  <tr key={entry.id}>
                    <td><strong>{t(entry?.name) || "-"}</strong></td>
                    <td>
                      {
                        entry?.time_last_activated
                          ? parseISO(entry.time_last_activated) < (new Date() - 24 * 60 * 60 * 1000)
                            ? format(parseISO(entry.time_last_activated), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })
                            : formatDistanceToNow(parseISO(entry.time_last_activated), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                          : "-"
                      }
                    </td>
                    <td>{entry?.had_success_last_run?.toString() || "-"}</td>
                    <td>
                      {
                        entry?.user_last_activated
                          ? parseISO(entry.user_last_activated) < (new Date() - 24 * 60 * 60 * 1000)
                            ? format(parseISO(entry.user_last_activated), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })
                            : formatDistanceToNow(parseISO(entry.user_last_activated), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                          : "-"
                      }
                    </td>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        className="shadow-sm me-1"
                        onClick={(e) => window.confirm(`Are you sure you wish to run "${t(entry.name)}" on the IoT gateway?`) && handleActivate(e, entry.id)}>
                        {t("Select")}
                      </Button>
                    </td>
                  </tr>
                );
              })}</tbody>
            </Table>
          </Col>
        </Row>

        <br />
        <hr />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default GatewayJobOptionsModal;