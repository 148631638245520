import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Plus } from "react-feather";
import LoadingSpinner from "./LoadingSpinner";
import { getCoreBaseURL } from "../../config";

const UploadFileModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const notyf = useContext(NotyfContext);
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const [loading, setLoading] = useState(false);


  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleuploadFile = event => {
    uploadFile(event);
  };

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  // useEffect(() => {

  //   if (openModal) {
  //     axios
  //       .get(getCoreBaseURL() + "/openapi.json")
  //       .then(function (response) {
  //         // handle success
  //         console.log(response.status, "/openapi.json");
  //         setAccessLevels(response.data["components"]["schemas"]["FileAccessLevel"]["enum"]);
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error);
  //       });
  //   }

  // }, [openModal]);

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const [selectedAccessLevel, setSelectedAccessLevel] = useState("");
  // const [permissionLevels, setAccessLevels] = useState([])

  function uploadFile(e) {
    e.preventDefault();

    setLoading(true)

    if (selectedFile === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Please select a new file first") });
    } else {
      // Create FormData object to handle file upload
      var formData = new FormData();
      formData.append("file", selectedFile);

      // Include metadata like access_level in the request body
      const metadata = {
        // title: "Your File Title", // Replace with your actual title
        // file_type: "Your File Type", // Replace with your file type
        access_level: selectedAccessLevel, // Example value for access_level
        // is_public: false, // Example value
        // uploading_user_id: "user_id_here", // Replace with the user ID
      };

      // FormData does not directly support nested objects, so stringify metadata
      formData.append("metadata", JSON.stringify(metadata));

      axios
        .post("/" + props.type + "s/" + id + "/files?" + props.type + "_id=" + id, formData, {
          headers: {
            "Content-Type": 'multipart/form-data'
          }
        })
        .then(function (response) {
          // handle success
          console.log("File uploaded");
          notyf.open({
            type: "success",
            message: t("File uploaded"),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          });
          toggle(false);

          window.location.reload(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false)

          setError({ showMessage: true, errorData: error });
        });
    }
  }

  const ButtonForType = () => {
    switch (props?.buttonType) {
      case "small":
        return (<Button
          onClick={() => toggle()}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <Plus className="feather" />  {t("Upload")}
        </Button>)
      default:
        return (<Dropdown.Item onClick={() => toggle()}>{t("Upload file")}</Dropdown.Item>)
    }
  }


  return (
    <>
      <ButtonForType />
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Upload File")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <br />
          {loading ? <LoadingSpinner /> : <Form>
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Control type="file" ref={hiddenFileInput} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
            {/* <br />
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Access level")}</Form.Label>
                  <Form.Select
                    name="state"
                    required
                    onChange={e => setSelectedAccessLevel(e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {permissionLevels?.map((item) => {
                      return (
                        <option key={item} value={item}>{t(item)}</option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}
          </Form>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={handleuploadFile}>
            <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default UploadFileModal;
