import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Info,
  Plus,
  Trash,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import UserDataManager from "../../Helper/UserDataManager";
import { format, formatDistanceToNow, formatDuration, intervalToDuration, isToday, isWithinInterval, isYesterday, parseISO, startOfWeek, subDays } from "date-fns";
import SingleMaintenanceEventModal from "../../Maintenance/SingleMaintenanceEventModal";
import usePalette from "../../../hooks/usePalette";
import AssetDigitalTwinChartModal from "../AssetDigitalTwin/AssetDigitalTwinChartModal";
import SingleIoTDataPointModal from "../IoT_Data_Setup/SingleIoTDataPointModal";
import Chart from "react-apexcharts";
import { cloneDeep } from 'lodash'; // Import lodash for deep clone if needed

const MaintenanceSection = ({ assetData, maintenanceContentLoaded }) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceEvents, setServiceEvents] = useState(false);
  const [refresh, set_refresh] = useState(true);
  const palette = usePalette();
  const [violations, setViolations] = useState();
  const [violationsClustered, setViolationsClustered] = useState();
  const [violationsClusteredInfos, setViolationsClusteredInfos] = useState();
  const [filteredViolations, setFilteredViolations] = useState();
  let { id } = useParams();
  const [rankedViolations, setRankedViolations] = useState();
  const [rankedViolationsClustered, setRankedViolationsClustered] = useState();
  const [filter_id, set_filter_id] = useState(""); // Store the user input

  const [showChart, setShowChart] = useState();
  const [assetHealthData, setAssetHealthData] = useState();

  useEffect(() => {

    if (maintenanceContentLoaded) {

      setAssetHealthData(assetData)

      loadViolations(null);

      axios
        .get("/assets/" + assetData?.id + "/violations/clustered", {
          params: {
            add_infos: true,
            period_start: new Date(new Date() - 24 * 60 * 60 * 1000 * 7).toISOString(),
            fill_gaps: true,
            // clustering_unit: "1 hour"
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/violations/clustered");
          // Processing violation data
          const processedViolations = response.data.results?.map((violation) => {
            const totalViolationsForNodes = violation.nodes.map((node) => ({
              iot_node_id: node.iot_node_id,
              asset_metric_id: node.asset_metric_id, // Keep the asset_metric_id as well
              violation_count: node.violation_count,
            }));

            return {
              interval_start: violation.interval_start,  // Add interval_start
              total_violations: totalViolationsForNodes.reduce((acc, node) => acc + node.violation_count, 0),
              nodes: totalViolationsForNodes,  // Include the nodes for further use
            };
          })
            ?.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)); // Sort by interval_start in ascending order

          // Update state for violationsClustered and infos
          setViolationsClustered(processedViolations);
          setViolationsClusteredInfos(response.data.infos);

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/assets/" + assetData?.id + "/service_events/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/service_events");
          setServiceEvents(response.data?.sort((a, b) => a?.appointment < b?.appointment))
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [refresh, maintenanceContentLoaded, assetData?.id]);

  useEffect(() => {
    if (violationsClustered) {
      // Create a map to accumulate the violation counts for each iot_node_id or asset_metric_id
      const occurrencesMap = violationsClustered.reduce((acc, interval) => {
        interval.nodes.forEach((node) => {
          const key = node.iot_node_id || node.asset_metric_id;
          if (key) {
            acc[key] = (acc[key] || 0) + node.violation_count; // Increment the count for each node
          }
        });
        return acc;
      }, {});

      // Convert the map to an array of objects and sort by occurrences
      const rankedViolationsClustered = Object.entries(occurrencesMap)
        .sort(([, a], [, b]) => b - a) // Sort by occurrence count (descending)
        .map(([key, count]) => ({
          id: key,
          occurrences: count,
        }));

      // Update the rankedViolationsClustered state with sorted violations
      setRankedViolationsClustered(rankedViolationsClustered);
    }
  }, [violationsClustered]);

  const [showSingleMaintenanceEvent, setShowSingleMaintenanceEvent] = useState(false)
  const [selected_event, set_selected_event] = useState()
  const [showIoTDataPointConfig, setShowIoTDataPointConfig] = useState(null)

  function handleDelete(e, id) {
    e.preventDefault();

    axios
      .delete("/assets/" + assetData?.id + "/service_events/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted event");
        // handle success
        set_refresh(!refresh)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderStatus = (status) => {
    switch (status) {
      case "completed":
        return <Badge bg="" className="badge-soft-success me-2">{t("Completed")}</Badge>
      case "overdue":
        return <Badge bg="" className="badge-soft-warning me-2">{t("Overdue")}</Badge>
      case "planned":
        return <Badge bg="" className="badge-soft-primary me-2">{t("Planned")}</Badge>
      default:
        return <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
    }
  }

  const RenderSecondsDuration = ({ seconds }) => {

    return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), { format: ['days', 'hours', 'minutes', "seconds"], locale: i18n.language === "de" ? de : null });

  }

  const RenderComparison = ({ mode }) => {

    switch (mode) {
      case "higher":
        return t("higher than threshold")
      case "lower":
        return t("lower than threshold")
      case "equals":
        return t("not equal to desired value")
      default:
        return ""
    }
  }

  const RenderSeverityLevel = ({ level }) => {

    switch (level) {
      case "critical":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t(level)}</Badge>
        )
      case "warning":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(level)}</Badge>
        )
      case "info":
        return (
          <Badge bg="" className="badge-soft-primary me-2">{t(level)}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t(level || "-")}</Badge>
        )
    }
  }


  const ShowRuleViolatedBadge = (violated) => {

    switch (violated) {
      case false:
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("OK")}</Badge>
        )
      case true:
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Violated")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }

  const [rawDataTable, setRawDataTable] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10); // Set this based on your total results
  const [skip, setSkip] = useState();
  const [limit, setLimit] = useState();

  function loadViolations(event = null, { newSkip = null } = {}) {
    // Prevent default behavior if an event is passed
    if (event) {
      event.preventDefault();
    }

    // If newSkip is passed, update the skip state
    if (newSkip !== null) {
      setSkip(newSkip);
    }

    setIsLoading(true);

    axios
      .get("/assets/" + assetData?.id + "/violations", {
        params: {
          add_infos: true,
          period_start: new Date(new Date() - 24 * 60 * 60 * 1000 * 14).toISOString(),
          skip: newSkip !== null ? newSkip : skip,  // Use newSkip if provided
          limit: limit === "" ? 100 : limit,  // Default limit to 50 if not specified
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/violations");
        setViolations(response.data)

        // Rank violations by occurrences of iot_node_id or asset_metric_id
        const occurrencesMap = response.data?.results?.reduce((acc, violation) => {
          const key = violation.iot_node_id || violation.asset_metric_id;
          if (key) {
            acc[key] = (acc[key] || 0) + 1; // Increment the count for each occurrence
          }
          return acc;
        }, {});

        // Convert the map to an array of objects and sort by occurrences
        const rankedViolations = Object.entries(occurrencesMap).sort(([, a], [, b]) => b - a).map(([key, count]) => ({
          id: key,
          occurrences: count,
        }));

        setRankedViolations(rankedViolations); // Update the rankedViolations state with sorted violations
        setIsLoading(false);

      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsLoading(false);
      });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newSkip = (page - 1) * (limit || 50);  // Calculate new skip value
    loadViolations(null, { newSkip });  // Call loadLogs with the new skip value
  };

  function Pagination({ currentPage, totalPages, onPageChange }) {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage - 1)}>{t("Previous")}</Button>
        )}
        {pages.map((page) => (
          <Button
            key={page}
            variant="outline-secondary" size="sm" className="shadow-sm me-1"
            onClick={() => onPageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </Button>
        ))}
        {currentPage < totalPages && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage + 1)}>{t("Next")}</Button>
        )}
      </div>
    );
  }

  const RenderUnit = (unit) => {

    switch (unit) {
      case "state":
        return ""
      default:
        return unit
    }
  }

  const RenderLocalizedRuleComponent = (item) => {

    return (<div
      key={item?.id}
      // onClick={() => handleClickOnViolation(item?.iot_node_id)}
      style={{ cursor: "pointer" }}
      className="mb-2">
      {ShowRuleViolatedBadge(item?.violated)} <strong>{t(item?.technical_name) || "-"}</strong>: {t("Currently")} <strong>{item?.last_value?.toLocaleString(i18n.language === "de" ? de : "en", {
        style: 'decimal',
        maximumFractionDigits: 1,
      })} {RenderUnit(item?.unit)} </strong> ({
        item?.lower_limit && item?.upper_limit // Upper and lower limit
          ? `${t("should be between")} ${item?.lower_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'decimal', maximumFractionDigits: 1,
          })} ${t("and")} ${item?.upper_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'decimal', maximumFractionDigits: 1,
          })} ${RenderUnit(item?.unit)}`

          : item?.lower_limit  // Only lower limit
            ? `${t("should be")} > ${item?.lower_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal', maximumFractionDigits: 1,
            })} ${RenderUnit(item?.unit)}`

            : item?.upper_limit // Only upper limit
              ? `${t("should be")} < ${item?.upper_limit?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal', maximumFractionDigits: 1,
              })} ${RenderUnit(item?.unit)}`
              : ``}{item?.target_value ? `${(item?.upper_limit || item?.lower_limit) ? "; " : ""}${t("Target Value")} ${t("is")} ${item?.target_value?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal', maximumFractionDigits: 1,
              })})` : ")"}
    </div>)
  }

  function loadAssetDetails() {

    setIsLoading(true)

    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {

        // handle success
        console.log(response.status, "/asset/base");

        setAssetHealthData(response.data);
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false)
        console.log(error);
      });
  }

  const [selectedNodeId, setSelectedNodeId] = useState(null)

  function formatDataDiff(targetDate) {

    const currentDate = new Date();
    const timeDifference = currentDate - targetDate;

    if (timeDifference <= 1 * 60 * 1000) {
      return t("Just now")
    } else {
      return formatDistanceToNow(targetDate, { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })
    }
  }

  const [filterLevel, setFilterLevel] = useState("all");
  const [filterTime, setFilterTime] = useState("today");

  // This is where filtering happens
  useEffect(() => {
    let updatedList = cloneDeep(violations?.results); // Create a copy to avoid mutations

    if (filterLevel !== "all") {
      updatedList = updatedList?.filter(v => v?.level === filterLevel);
    }

    if (filterTime !== "all") {
      if (filterTime === "today") {
        updatedList = updatedList?.filter(v => isToday(parseISO(v?.created_at)));
      } else if (filterTime === "yesterday") {
        updatedList = updatedList?.filter(v => isYesterday(parseISO(v?.created_at)));
      }
    }

    if (filter_id) {
      updatedList = updatedList?.filter(v => v.iot_node_id === filter_id || v.asset_metric_id === filter_id);
    }

    // setCurrentPage(1)

    setFilteredViolations(updatedList);

    // Debugging logs to check the filtering results
    // console.log("Updated Filtered Violations:", updatedList);
    // console.log("Filter ID:", filter_id);
    // console.log("Filter Level:", filterLevel);
  }, [filterLevel, filter_id, violations, filterTime]);

  const renderHealthState = (status) => {

    switch (status) {
      case "healthy":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Healthy")}</Badge>
        )
      case "warning":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Warning")}</Badge>
        )
      case "unhealthy":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t("Warning")}</Badge>
        )
      case "offline":
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("Offline")}</Badge>
        )
      case "no_asset_connection":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }

  const AssetState = () => {

    const renderState = (status) => {

      switch (status) {
        case "state_running":
          return (
            <Badge bg="" className="badge-soft-success me-2">{t("running")}</Badge>
          )
        case "state_setup":
          return (
            <Badge bg="" className="badge-soft-primary me-2">{t("setup")}</Badge>
          )
        case "state_error":
          return (
            <Badge bg="" className="badge-soft-danger me-2">{t("short_error")}</Badge>
          )
        case "no state_maintenance":
          return (
            <Badge bg="" className="badge-soft-warning me-2">{t("short_maintenance")}</Badge>
          )
        default:
          return (
            <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
          )
      }
    }

    return (
      assetHealthData?.health?.current_states?.length > 0 ?
        <div className="d-flex flex-wrap">
          {
            assetHealthData?.health?.current_states?.map((reason) => {
              return (<h3 key={reason} className="mb-2" >{renderState(reason)}</h3>)
            })
          }
        </div>
        :
        <div><Badge bg="" className="badge-soft-secondary me-2">{t("-")}</Badge></div>
    )
  }

  const Violations = () => {

    // console.log("Health array ----->", assetHealthData?.health?.health_rules)

    return (
      <Card className="flex-fill">
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>{t("Current state")}</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end mt-3">
              <Button
                onClick={() => loadData()}
                variant={"outline-primary"}
                size="sm"
                className="shadow-sm me-1">
                <RefreshCw className="feather" />
              </Button>
            </Col> */}
          </Row>
        </Card.Header>
        <Card.Body>

          <Row>
            <Col md={6} >
              {/* Healthy State */}
              <p className="mb-2">{t("Condition")}</p>
              <h3>
                {renderHealthState(assetHealthData?.health?.status)}
              </h3>
            </Col>
            <Col md={6} >
              {assetHealthData?.health?.current_states?.length > 0 && <Row>
                <p className="mb-2">{t("Current Asset State")}</p>
                <AssetState />
              </Row>}
              <Row className="mt-4">
                <Col>
                  <div className="d-flex-wrap">
                    {assetHealthData?.health?.current_alerts?.length > 0 &&
                      assetHealthData?.health?.current_alerts?.map((item) => {
                        return <div
                          key={item?.type_number}
                          // style={{ cursor: "pointer" }}
                          className="mb-2 me-2">
                          <Badge bg="" className="badge-soft-success me-2">{t(item?.technical_name)}</Badge>
                        </div>
                      })}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>

              <div className="d-flex-wrap">
                {assetHealthData?.health?.health_rules
                  // ?.filter(item => item?.violated !== null)
                  ?.sort((a, b) => {
                    // First, sort by isActive (true first)
                    if (a.violated === b.violated) {
                      // If isActive is the same, sort by name
                      return a?.technical_name?.localeCompare(b?.technical_name);
                    }
                    return a.violated ? -1 : 1;
                  })
                  ?.map((item) => {
                    return (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="top"
                        key={item?.id}
                        overlay={
                          <Popover key={item?.id}>
                            {/* <Popover.Header as="h3">{`${item?.display_name}`}</Popover.Header> */}
                            <Popover.Body>
                              <h4>{RenderLocalizedRuleComponent(item)}</h4><br />
                              <strong>{t("Last update")}: </strong> {item?.asset_log_time ? formatDataDiff(parseISO(item?.asset_log_time)) : '-'}<br /><br />

                              <Button variant="outline-primary" size="sm" className="shadow-sm me-1 mt-2" onClick={() => setSelectedNodeId(item?.iot_node_id)}>
                                {t("View chart")}
                              </Button>
                              <Button variant="outline-primary" size="sm" className="shadow-sm me-1 mt-2" onClick={() => setShowIoTDataPointConfig(item?.iot_node_id)}>
                                {t("Update rule")}
                              </Button>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {RenderLocalizedRuleComponent(item)}
                      </OverlayTrigger>
                    )
                  })}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  const UsageBarChart = ({ x_data, y_data_series, title, subtitle }) => {
    const palette = usePalette();

    const getTickAmount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) {
        return 10; // More ticks for larger screens
      } else if (screenWidth > 768) {
        return 7; // Medium amount of ticks for tablet screens
      } else {
        return 5; // Fewer ticks for mobile screens
      }
    };

    const series = y_data_series;

    const options = {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "smooth",
        width: 2,
      },
      markers: {
        size: 4,
        colors: [palette.primary],
      },
      tooltip: {
        y: {
          formatter: (value) => value,
        },
      },
      xaxis: {
        tickAmount: getTickAmount(),
        categories: x_data,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => value,
        },
      },
      colors: [palette.primary, palette.secondary, palette.info, palette.warning],
    };

    return (
      y_data_series?.length > 0
        ? <div className="chart">
          <Chart options={options} series={series} type="bar" height={280} />
        </div>
        : <p>{t("No data found.")}</p>
    );
  };

  const ViolationsBarChart = ({ violations = [], startTime, endTime }) => {
    const palette = usePalette();

    // Ensure violations is a valid array
    if (!Array.isArray(violations)) {
      return <p>No data available for violations</p>;
    }

    // Calculate the total number of seconds from startTime to endTime
    const totalSeconds = Math.floor((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000);

    // Generate an array of seconds from start to end
    const secondsOfDay = Array.from({ length: totalSeconds }, (_, index) => index);

    // Map violations to seconds of the day
    const violationsBySecond = secondsOfDay.map((second) => {
      return violations.reduce((acc, violation) => {
        const violationStart = Math.floor((new Date(violation.period_start).getTime() - new Date(startTime).getTime()) / 1000);
        const violationEnd = Math.floor((new Date(violation.period_end).getTime() - new Date(startTime).getTime()) / 1000);

        // Check if the violation occurred within this second
        if (second >= violationStart && second <= violationEnd) {
          return acc + 1;
        }
        return acc;
      }, 0);
    });

    const series = [
      {
        name: "Violations",
        data: violationsBySecond,
      },
    ];

    const options = {
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: secondsOfDay?.map((second) => `${second} s`), // Label each second
        title: {
          text: "Seconds from Start Time",
        },
      },
      yaxis: {
        title: {
          text: "Number of Violations",
        },
      },
      // colors: (seriesData) => {
      //   // Highlight bars where violations occurred in orange
      //   return seriesData.map((value) => (value > 0 ? "#FFA500" : palette.primary));
      // },
    };

    return <Chart options={options} series={series} type="bar" height={350} />;
  };

  const ViolationsLog = () => {
    return (<Card>
      <Card.Header>
        <Col>
          <Card.Title>{t("Violation history")}</Card.Title>
        </Col>
        {/* <Col xs="auto" className="ms-auto text-end mt-n4">
          <Button
            onClick={() => {
              set_selected_event(null)
              setShowSingleMaintenanceEvent(true)
            }}
            size="sm"
            variant="outline-primary"
            className="shadow-sm me-1">
            <Plus className="feather" /> {t("New")}
          </Button>
        </Col> */}


      </Card.Header>
      <Card.Body>

        <div>
          <div className="mb-3 d-flex">
            <div className="mb-1 me-3">{t("Severity")}
              <ButtonToolbar className="mt-1">
                <ButtonGroup>
                  <Button
                    variant={filterLevel === "all" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterLevel("all")}>
                    {t("Show all")}
                  </Button>
                  <Button
                    variant={filterLevel === "info" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterLevel("info")}>
                    {t("Info")}
                  </Button>
                  <Button
                    variant={filterLevel === "warning" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterLevel("warning")}>
                    {t("Warning")}
                  </Button>
                  <Button
                    variant={filterLevel === "critical" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterLevel("critical")}>
                    {t("Critical")}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>

            <div className="mb-1 me-3">{t("Occurrence")}
              <ButtonToolbar className="mt-1">
                <ButtonGroup>
                  {/* <Button
                    variant={filterTime === "last_week_end" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => filterTime === "last_week_end" ? setFilterTime("all") : setFilterTime("last_week_end")}>
                    {t("Last weekend")}
                  </Button> */}
                  <Button
                    variant={filterTime === "all" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterTime("all")}>
                    {t("Show all")}
                  </Button>
                  <Button
                    variant={filterTime === "yesterday" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterTime("yesterday")}>
                    {t("Yesterday")}
                  </Button>
                  <Button
                    variant={filterTime === "today" ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setFilterTime("today")}>
                    {t("Today")}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>

            <div className="mb-1 me-3">{t("Table design")}
              <ButtonToolbar className="mt-1">
                <ButtonGroup>
                  <Button
                    variant={!rawDataTable ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setRawDataTable(!rawDataTable)}>
                    {t("Simplified data")}
                  </Button>
                  <Button
                    variant={rawDataTable ? "secondary" : "outline-secondary"}
                    size="sm"
                    onClick={() => setRawDataTable(!rawDataTable)}>
                    {t("Organized")}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>

            <div className="mb-1 me-3">

              <Form.Group >
                <Form.Label>{t("IoT Data Point")} {t("or")} {t("KPI")}</Form.Label>
                <Form.Select
                  name="state"
                  className="mt-n1"
                  onChange={(e) => set_filter_id(e.target.value)}>
                  <option value={""}>{t("Show all")}</option>
                  {violations?.infos?.map((item) => {
                    return (
                      <option key={item.name} value={item.iot_node_id || item.asset_metric_id} selected={(item.iot_node_id || item.asset_metric_id) === filter_id}>{item.name}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

            </div>
          </div>

          {isLoading === true ?
            <LoadingSpinner withStandardText />

            : <div>
              <Row>
                <Col xs="auto" className="ms-auto text-center">
                  <div className="px-3 py-3 mt-2 mb-2">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  {/* <ViolationsBarChart
                    violations={filteredViolations}
                    startTime="2023-09-19T00:00:00Z"
                    endTime="2023-09-T23:59:59Z"
                  /> */}

                </Col>
              </Row>
              <Row>
                <Col md="9">
                  {
                    rawDataTable
                      ? <Table responsive hover>
                        <thead>
                          <tr>
                            <th scope="col">{t("Severity")}</th>
                            <th scope="col">{t("Name")}</th>
                            <th scope="col">{t("Time span")}</th>
                            <th scope="col">{t("Duration")}</th>
                            <th scope="col">{t("Actual value")}</th>
                            <th scope="col">{t("Threshold")}</th>
                            <th scope="col">{t("Difference")}</th>
                            <th scope="col">{t("Logic")}</th>
                          </tr>
                        </thead>
                        <tbody>{

                          filteredViolations?.length > 0
                            ? // If data found

                            filteredViolations?.map((violation) => {

                              const info_for_violation = violations?.infos?.find(
                                (info) =>
                                  (violation?.iot_node_id ? violation?.iot_node_id === info?.iot_node_id : false)
                                  || (violation?.asset_metric_id ? violation?.asset_metric_id === info?.asset_metric_id : false)
                              );

                              return (
                                <tr key={violation?.id}
                                  style={{ cursor: "pointer" }}
                                  onClick={e => violation?.iot_node_id && setShowChart(violation)}
                                >
                                  <td><RenderSeverityLevel level={violation?.level} /></td>
                                  <td><strong>{t(info_for_violation?.name || "-")}</strong></td>
                                  <td>{format(parseISO(violation?.period_start), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })} - {format(parseISO(violation?.period_end), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })}                                  </td>
                                  <td><RenderSecondsDuration seconds={violation?.seconds} /></td>
                                  <td>{violation?.actual_value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} {info_for_violation?.unit}</td>
                                  <td>{violation?.threshold?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} {info_for_violation?.unit}</td>
                                  <td>{((violation?.actual_value - violation?.threshold) / violation?.threshold)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0 })}</td>
                                  <td><RenderComparison mode={violation?.comparison_mode} /></td>
                                </tr >
                              );
                            })
                            : // If no data found
                            <tr>
                              <td colSpan="2">
                                {t("No data found.")}
                              </td>
                            </tr>
                        }
                        </tbody>
                      </Table>
                      : <Table responsive hover>
                        <thead>
                          <tr>
                            <th scope="col">{t("Severity")}</th>
                            <th scope="col">{t("Message")}</th>
                            {/* <th scope="col">{t("Actual value")}</th> */}
                          </tr>
                        </thead>
                        <tbody>{

                          filteredViolations?.length > 0
                            ? // If data found

                            filteredViolations?.map((violation) => {

                              const info_for_violation = violations?.infos?.find(
                                (info) =>
                                  (violation?.iot_node_id ? violation?.iot_node_id === info?.iot_node_id : false)
                                  || (violation?.asset_metric_id ? violation?.asset_metric_id === info?.asset_metric_id : false)
                              );

                              return (
                                <tr key={violation?.id}
                                  style={{ cursor: "pointer" }}
                                  onClick={e => violation?.iot_node_id && setShowChart(violation)}
                                >
                                  <td><RenderSeverityLevel level={violation?.level} /></td>
                                  <td>
                                    <OverlayTrigger
                                      placement='bottom'
                                      overlay={
                                        <Popover>
                                          <Popover.Header as='h3'>{t("Time span")}</Popover.Header>
                                          <Popover.Body>
                                            {format(parseISO(violation?.period_start), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })} - {format(parseISO(violation?.period_end), "d MMM HH:mm:ss", { locale: i18n.language === "de" ? de : null })}
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <Info className="feather me-2" />
                                    </OverlayTrigger>
                                    <strong>{t(info_for_violation?.name || "-")}</strong> {t("was")} <strong>{violation?.actual_value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} {info_for_violation?.unit}</strong>
                                    {" "} (<RenderComparison mode={violation?.comparison_mode} /> <strong>{violation?.threshold?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} {info_for_violation?.unit}</strong>) {t("for")} <RenderSecondsDuration seconds={violation?.seconds} /> {t("starting")} <strong>{format(parseISO(violation?.period_start), `d${i18n.language === "de" ? "." : ""} MMM, HH:mm:ss`, { locale: i18n.language === "de" ? de : null })} {i18n.language === "de" ? "Uhr" : null}</strong>.</td>


                                  {/* {violation?.iot_node_id &&
                        <td className="table-action">
                          <BarChart alt="chart" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                            e.stopPropagation();
                            setShowChart(violation);
                          }
                          } />
                        </td>} */}

                                </tr >
                              );
                            })
                            : // If no data found
                            <tr>
                              <td colSpan="2">
                                {t("No data found.")}
                              </td>
                            </tr>
                        }
                        </tbody>
                      </Table>
                  }
                </Col>
                <Col md="3">
                  {/* <ViolationsOverview /> */}
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Data Point")}</th>
                        <th scope="col">{t("Occurences")}</th>
                        {/* <th scope="col">{t("Actual value")}</th> */}
                      </tr>
                    </thead>
                    <tbody>{rankedViolations?.map((violation) => {

                      const info_for_violation = violations?.infos?.find(
                        (info) => info?.iot_node_id === violation?.id || info?.asset_metric_id === violation?.id
                      );

                      return (
                        <tr key={violation?.id}
                          style={{ cursor: "pointer" }}
                          onClick={e => set_filter_id(info_for_violation?.iot_node_id || info_for_violation?.asset_metric_id)}
                        >
                          <td>{t(info_for_violation?.name || "-")}</td>
                          <td>{violation?.occurrences || "-"}</td>
                        </tr >
                      );
                    })}</tbody>
                  </Table>
                </Col>
              </Row>

            </div>
          }
        </div>

      </Card.Body>
    </Card>)
  }


  function infoForId(id) {

    return t(violationsClusteredInfos?.find(
      (info) =>
        id === info?.iot_node_id || id === info?.asset_metric_id
    )?.name || id)

  }

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>

    <SingleMaintenanceEventModal
      show={showSingleMaintenanceEvent}
      selected_event={selected_event}
      asset_id={assetData?.id}
      handleClose={() => {
        set_selected_event(null)
        set_refresh(!refresh)
        setShowSingleMaintenanceEvent(false)
      }}
    />

    {(showChart || selectedNodeId) && <AssetDigitalTwinChartModal
      handleClose={() => {
        setShowChart(null);
        setSelectedNodeId(null);
      }}
      desired_iot_node_id={showChart?.iot_node_id || selectedNodeId}
      desired_start_time={showChart?.period_start ? new Date(new Date(showChart?.period_start).getTime() - (60 * 5 * 1000)) : null} // Subtract 1 hour
      desired_end_time={showChart?.period_end ? new Date(new Date(showChart?.period_end).getTime() + (60 * 5 * 1000)) : null} // Add 1 hour
      asset_data={assetData}
      asset_id={id}
    />}

    {showIoTDataPointConfig && <SingleIoTDataPointModal
      existing_iot_node_id={showIoTDataPointConfig}
      showAdvanced
      asset_id={id}
      slim
      handleCancel={() => {
        setSelectedNodeId(null)
        setShowIoTDataPointConfig(null)
      }
      }
      handleSave={() => {
        loadAssetDetails()
        setSelectedNodeId(null)
        setShowIoTDataPointConfig(null)
      }}
    />}

    <Row className="mt-2 mb-4">
      <Col >
        <div className="h3">{t("Condition Monitoring")}</div>
      </Col>
      <Col xs="auto" className="ms-auto text-end">

        {/* <Button onClick={() => navigate("/assets/" + assetData?.id + "?focus=digital-twin")} variant="outline-primary" size="sm" className="shadow-sm me-1">
          <Wifi className="feather" /> {isMobile ? "" : t("Live-View")}
        </Button> */}

      </Col>
    </Row>

    <Row >
      <Col md="6" className="d-flex">
        <Violations />
      </Col>

      <Col md="6" className="d-flex">

        <Card className="flex-fill">

          <Row>
            <Col md="6">
              <Card.Header>
                <Card.Title tag="h5">{t("No. of Violations")}</Card.Title>
                <Card.Subtitle>{t("Last 7 days")}</Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <UsageBarChart
                  x_data={violationsClustered?.map((entry) =>
                    format(parseISO(entry?.interval_start), "dd MMM", { locale: i18n.language === "de" ? de : undefined })
                  )}
                  y_data_series={violationsClustered?.reduce((acc, entry) => {
                    entry.nodes.forEach((node) => {

                      const nodeIndex = acc.findIndex((series) => series.name === infoForId(node.iot_node_id));
                      if (nodeIndex > -1) {
                        acc[nodeIndex].data.push(node.violation_count);
                      } else {

                        acc.push({
                          name: infoForId(node.iot_node_id),
                          data: [node.violation_count],
                        });
                      }
                    });
                    return acc;
                  }, [])}
                />
              </Card.Body>

            </Col>

            <Col md="6">
              <Card.Header>
                <Card.Title>{t("Ranking")}</Card.Title>
                <Card.Subtitle>{t("Occurences by Data Point")}</Card.Subtitle>
              </Card.Header>
              <Card.Body>
                {rankedViolationsClustered?.length > 0
                  ? // If data found

                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Data Point")}</th>
                        <th scope="col">{t("Occurences")}</th>
                        {/* <th scope="col">{t("Actual value")}</th> */}
                      </tr>
                    </thead>
                    <tbody>{rankedViolationsClustered?.map((violation) => {

                      const info_for_violation = violationsClusteredInfos?.find(
                        (info) => info?.iot_node_id === violation?.id || info?.asset_metric_id === violation?.id
                      );

                      return (
                        <tr key={violation?.id}
                          style={{ cursor: "pointer" }}
                          onClick={e => set_filter_id(info_for_violation?.iot_node_id || info_for_violation?.asset_metric_id)}
                        >
                          <td>{t(info_for_violation?.name || violation?.id || "-")}</td>
                          <td>{violation?.occurrences || "-"}</td>
                        </tr >
                      );
                    })}</tbody>
                  </Table>
                  : // If no data found

                  <p className="card-subtitle text-muted">
                    {t("No data found.")}
                  </p>}
              </Card.Body>

            </Col>

          </Row>
        </Card>



      </Col>
    </Row>

    {/* <Row className="d-flex">
      <Col md={focusedSection === null ? "4" : focusedSection === "history" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Operational Log")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "history"
              ? <span className={"badge bg-warning"}>Coming soon</span>
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("history")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
      <Col md={focusedSection === null ? "4" : focusedSection === "current" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Current state")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "current"
              ? <AssetHealthCheckSection assetData={assetData} loadData={() => loadData()} />
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("current")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
      <Col md={focusedSection === null ? "4" : focusedSection === "forecast" ? "8" : "2"} style={{ display: 'flex' }}>
        <Card className="flex-fill d-flex flex-column">
          <Card.Header>
            <Card.Title>
              {t("Predictive Maintenance")}
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex flex-column flex-grow-1">
            {focusedSection === "forecast"
              ? <span className={"badge bg-warning"}>Coming soon</span>
              : <div className="mt-auto">
                <Button
                  onClick={() => setFocusedSection("forecast")}
                  size="sm"
                  variant="outline-primary"
                  className="shadow-sm me-1">
                  <Maximize2 className="feather" /> {t("Show")}
                </Button>
              </div>}
          </Card.Body>
        </Card>
      </Col>
    </Row> */}
    <Row>
      <Col md="12">
        <ViolationsLog />
      </Col>
    </Row>

    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Col>
              <Card.Title>{t("Maintenance Log")}</Card.Title>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n4">
              <Button
                onClick={() => {
                  set_selected_event(null)
                  setShowSingleMaintenanceEvent(true)
                }}
                size="sm"
                variant="outline-primary"
                className="shadow-sm me-1">
                <Plus className="feather" /> {t("New")}
              </Button>
            </Col>


          </Card.Header>
          <Card.Body>
            {isLoading === true ?
              <LoadingSpinner withStandardText /> :
              (serviceEvents.length > 0)
                ? // If data found

                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">{t("Status")}</th>
                      <th scope="col">{t("Date")}</th>
                      <th scope="col">{t("Title")}</th>
                    </tr>
                  </thead>
                  <tbody>{serviceEvents.map((event) => {

                    return (
                      <tr key={event?.id} onClick={() => {
                        set_selected_event(event)
                        setShowSingleMaintenanceEvent(true)
                      }
                      } style={{ cursor: "pointer" }}>
                        <td>{renderStatus(event?.status)}</td>
                        <td>{event?.appointment !== null
                          ? parseISO(event.appointment) < (new Date() - 24 * 60 * 60 * 1000)
                            ? format(parseISO(event.appointment), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null })
                            : formatDistanceToNow(parseISO(event.appointment), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                          : "-"
                        }</td>
                        <td>{event?.description}</td>

                        {UserDataManager.getInstance().isAdmin() &&
                          <td className="table-action">
                            <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                              e.stopPropagation();
                              window.confirm(t("Are you sure you wish to delete this entry?")) && handleDelete(e, event?.id)
                            }
                            } />
                          </td>}

                      </tr >
                    );
                  })}</tbody>
                </Table>

                : // If no data found

                <p className="card-subtitle text-muted">
                  {t("No data found.")}
                </p>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div >)
}

export default MaintenanceSection;
