import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge, Card, Table, Form } from "react-bootstrap";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import { Activity, CheckCircle, Edit2, Tool } from "react-feather";
import UserDataManager from "../Helper/UserDataManager";
import LoadingSpinner from "../Helper/LoadingSpinner";
import SingleAssetModal from "./SingleAssetModal";

// Custom Filter Components
const DefaultColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows } }) => {

  const { t } = useTranslation();

  // const count = preFilteredRows.length;
  return (
    <Form.Control
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`${t("Search")}`}
      className="mt-2"
    />
  );
};

const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach(row => options.add(row.values[id]));
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Form.Select value={filterValue} onChange={(e) => setFilter(e.target.value || undefined)}>
      <option value="">{t("All")}</option>
      {options?.map((option, i) => (
        <option key={i} value={option}>{t(option)}</option>
      ))}
    </Form.Select>
  );
};

const AssetTable = ({ data = [], title, isLoading, slim, showTitle = true }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState();


  const columns = useMemo(() => [
    ...(!slim
      ? [
        {
          Header: t("PLC Connection"),
          accessor: "connection_status",
          Filter: SelectColumnFilter,
          Cell: ({ value }) => getConnectionStatus(value),
        }
      ]
      : []),
    ...((data?.length < 6)
      ? [
        {
          Header: t(""),
          accessor: "avatar_url",
          disableFilters: true,
          Cell: ({ value }) => getImage(value),
        }
      ]
      : []),
    {
      Header: t("Title"),
      accessor: "title",
      Filter: DefaultColumnFilter,
      Cell: ({ value }) => <strong>{value || "-"}</strong>, // Make the value bold
    },
    {
      Header: t("Model"),
      accessor: "machine_model",
      Cell: ({ value }) => value || "-",
    },
    {
      Header: t("Location"),
      accessor: "location_name",
      Filter: DefaultColumnFilter,
      Cell: ({ value }) => value || "-",
    },
    ...(
      !slim
        ? [
          {
            Header: t("Condition Monitor"),
            accessor: "had_recent_violations",
            Filter: SelectColumnFilter,
            Cell: ({ value, row }) => getRecentViolationsStatus(value, row),
          },
          {
            Header: t("Client"),
            accessor: "client_name",
            Filter: DefaultColumnFilter,
            Cell: ({ value }) => value || "-",
          }]
        : []
    ),
    {
      Header: t("Quick Actions"),
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="table-action">
          <Edit2 size={16} onClick={(e) => handleEdit(e, row.original.id)} />
          &nbsp;&nbsp;&nbsp;
          <Activity size={16} onClick={(e) => handleActivity(e, row.original.id)} />
          &nbsp;&nbsp;&nbsp;
          <CheckCircle size={16} onClick={(e) => handleCondition(e, row.original.id)} />
          {UserDataManager.getInstance().isAdmin() && (
            <>
              &nbsp;&nbsp;&nbsp;
              <Tool size={16} onClick={(e) => handleIoTSetup(e, row.original.id)} />
            </>
          )}
        </div>
      ),
    },
  ], [t]);

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
    }),
  }), []);

  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter
  );

  const getImage = (url) => {

    if (url === "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png") {
      return null
    }

    return (
      <img
        src={url == null ? null : url}
        className="me-1 text-center"
        alt={""}
        // width={50} 
        height={40}
      />
    );
  }

  const getConnectionStatus = (status) => {
    switch (status) {
      case "connected":
        return <Badge bg="" className="badge-soft-success me-2">{t("connected")}</Badge>
      case "no_connection":
        return <Badge bg="" className="badge-soft-secondary me-2">{t("no_connection")}</Badge>;
      case "disconnected":
        return <Badge bg="" className="badge-soft-warning me-2">{t("disconnected")}</Badge>;
      default:
        return <span>-</span>;
    }
  };

  const getRecentViolationsStatus = (hadRecentViolations, row) => {
    return hadRecentViolations
      ? <Badge
        onClick={(e) => handleCondition(e, row.original.id)}
        bg=""
        className="badge-soft-warning me-2">{t("Please check")}</Badge>
      : null //<Badge bg="" className="badge-soft-success me-2">{t("OK")}</Badge>;
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    setSelectedAsset(id);
  };

  const handleActivity = (e, id) => {
    e.stopPropagation();
    navigate(`/assets/${id}/?focus=digital-twin`);
  };

  const handleCondition = (e, id) => {
    e.stopPropagation();
    navigate(`/assets/${id}/?focus=maintenance`);
  };

  const handleIoTSetup = (e, id) => {
    e.stopPropagation();
    navigate(`/assets/${id}/setup`);
  };


  return (
    <>
      <SingleAssetModal show={selectedAsset} selected_existing_asset={selectedAsset} handleClose={() => {
        setSelectedAsset(null);
        window.location.reload(false);
      }} />

      <Card className="flex-fill w-100">
        {(showTitle && title) && (
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">{t(title)}</Card.Title>
          </Card.Header>
        )}
        {isLoading ? (
          <LoadingSpinner withStandardText />
        ) : data?.length > 0 ? (
          <Table responsive hover {...getTableProps()}>
            <thead>
              {headerGroups?.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <div>{column.canFilter ? column.render("Filter") : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows?.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/assets/${row.original.id}`)} // Navigate on row click
                  >
                    {row.cells?.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Card.Body>
            <p>{t("No data found.")}</p>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default AssetTable;