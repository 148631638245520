import { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const GatewayOPCStartBrowseWithOptions = ({ handleCancel, handleRun, callback_options }) => {
  const { t, i18n } = useTranslation();

  // State for form inputs
  const [depth, setDepth] = useState(10);
  const [loadValues, setLoadValues] = useState(false);
  const [browseNodeId, setBrowseNodeId] = useState();

  // Update callback options whenever input values change
  useEffect(() => {
    if (callback_options) {
      callback_options.iter_depth = depth;
      callback_options.load_values = loadValues;
      callback_options.browse_node_id = browseNodeId;
    }
  }, [depth, loadValues, browseNodeId, callback_options]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleRun(callback_options); // Pass updated options to handleRun
  };

  return (
    <Modal size="ms" centered show onHide={handleCancel} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>{t("Browsing Options")}</Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Iteration Depth")}</Form.Label>
                <Form.Control
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                  type="number"
                  placeholder="Default: 100"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Start Node ID (optional)")}</Form.Label>
                <Form.Control
                  value={browseNodeId}
                  onChange={(e) => setBrowseNodeId(e.target.value)}
                  placeholder="ns=2;s=Demo"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  onChange={() => setLoadValues(!loadValues)}
                  checked={loadValues}
                  label={t("Include example values")}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-primary" onClick={handleCancel}>
              {t("Cancel")}
            </Button>
            <Button variant="primary" className="ms-1" onClick={onSubmit}>
              {t("Browse")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal >
  );
};

export default GatewayOPCStartBrowseWithOptions;