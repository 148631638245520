import { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import OPCUA_Logo_Gross from "../../assets/img/logos/opc_logo.png";
import GatewayOPCUANodeTreeRenderer from "./GatewayOPCUANodeTreeRenderer";
import SingleIoTDataPointModal from "../Assets/IoT_Data_Setup/SingleIoTDataPointModal";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import qs from "qs";

const GatewayOPCUABrowserModal = ({ asset_id, handleClose }) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [showDataPointModal, setShowDataPointModal] = useState(null);

  useEffect(() => {
    loadInitialData();
    loadConfigs();
  }, []);

  function loadConfigs() {
    axios
      .get("/assets/" + asset_id + "/iot_configs/")
      .then(function (response) {

        // handle success
        console.log(response.status, "/assets/iot_config");

        setConfigData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const loadInitialData = async () => {
    setIsLoading(true);
    try {
      const [iotOverviewResponse, configResponse] = await Promise.all([
        axios.get(`/assets/${asset_id}/iot_overview`, { params: { path: null } }),
        axios.get(`/assets/${asset_id}/iot_configs/`),
      ]);
      console.log(iotOverviewResponse.status, "/loadMoreNodes:"); //, iotOverviewResponse.data
      setData(iotOverviewResponse.data);
      setConfigData(configResponse.data);
    } catch (error) {
      console.error("Error loading initial data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreNodes = async (path, plc_id) => {
    // console.log("loadMoreNodes called with path:", path, "plc_node_id:", plc_id);
    try {
      const response = await axios.get(`/assets/${asset_id}/iot_overview`, {
        params: { path, plc_node_id: plc_id },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      });
      console.log(response.status, "/loadMoreNodes:");
      return response.data.nodes[0].subnodes || [];
    } catch (error) {
      console.error("Error loading more nodes:", error);
      return [];
    }
  };

  const handleDelete = async (e, plc_node_id) => {
    e.preventDefault();
    if (!window.confirm(t("Are you sure you wish to delete this data point?"))) return;

    try {
      await axios.delete(`/assets/${asset_id}/iot_nodes/plc_node_id/${plc_node_id}`);
      await loadInitialData(); // Reload data after deletion
    } catch (error) {
      console.error("Error deleting node:", error);
    }
  };

  return (
    <Modal size="xl" show onHide={handleClose} style={{ zIndex: 1050 }}>
      <Modal.Body>
        {showDataPointModal && (
          <SingleIoTDataPointModal
            desired_protocol="opc_ua"
            existing_iot_node_id="new"
            opc_ua_browser_plc_node_id={showDataPointModal?.plc_node_id}
            opc_ua_browser_display_name={showDataPointModal?.display_name}
            asset_id={asset_id}
            handleCancel={() => {
              setShowDataPointModal(null);
            }}
            handleSave={() => {
              loadInitialData();
              setShowDataPointModal(null);
            }}
          />
        )}

        <Row>
          <Col>
            {isLoading ? (
              <div className="text-center">
                <img src={OPCUA_Logo_Gross} alt="OPC UA" height={100} className="mb-4" /><br />
                <Spinner animation="border" variant="secondary" /> <br /><br />
                <h3>{t("Browsing OPC UA Server")}</h3>
                <p>{t("This will take a few seconds. Lean back!")}</p>
              </div>
            ) : (
              <>
                <div className="text-center mb-4">
                  <img src={OPCUA_Logo_Gross} alt="OPC UA" height={100} />
                  <p>{t("We found the following categories and OPC UA data points in the connected PLC.")}</p>
                  <p>
                    {t("Last update")}:{" "}
                    <strong>
                      {data?.time
                        ? format(parseISO(data.time), "eee, d MMMM HH:mm", {
                          locale: i18n.language === "de" ? de : null,
                        })
                        : "-"}
                    </strong>
                  </p>
                  {data?.error && <p className="text-danger">{t("Error")}: {data.error}</p>}
                </div>

                <GatewayOPCUANodeTreeRenderer
                  nodes={data?.nodes || []}
                  loadMoreNodes={loadMoreNodes}
                  configData={configData}
                  setShowDataPointModal={setShowDataPointModal}
                  handleDelete={handleDelete}
                />
              </>
            )}
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="text-end">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default GatewayOPCUABrowserModal;