import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Dropdown } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";

const SingleIoTGatewayModal = ({ handleClose, existingGatewayId }) => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [gatewayData, setGatewayData] = useState({
    serial: "",
    passcode: "",
    tailscale_node_id: "",
    internal_ip: "",
    node_red_password: ""
  });

  useEffect(() => {
    if (existingGatewayId) {

      axios
        .get(`/webservice/iot_gateways/${existingGatewayId}`)
        .then(function (response) {
          // handle success
          console.log(response.status, "/iot_gateway");
  
          setGatewayData(response.data);
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }, [existingGatewayId]);

  const handleChange = (field) => (event) => {
    setGatewayData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!gatewayData.serial) {
      setError({ showMessage: true, errorData: null, customMessage: t("Please enter a gateway serial.") });
      return;
    }

    setError({ showMessage: false, errorData: null });

    const apiCall = existingGatewayId
      ? axios.put(`/iot_gateways/${existingGatewayId}`, gatewayData)
      : axios.post("/iot_gateways/", gatewayData);

    apiCall
      .then((response) => {
        notyf.open({
          type: "success",
          message: existingGatewayId
            ? t("Gateway \"") + gatewayData.serial + t("\" has been updated.")
            : t("New gateway \"") + gatewayData.serial + t("\" has been added."),
          duration: 4500,
          ripple: false,
          dismissible: true,
          position: { x: "right", y: "bottom" },
        });
        navigate(`/gateways/${response.data.id}`);
        handleClose();
      })
      .catch((error) => setError({ showMessage: true, errorData: error }));
  };

  return (
    <Modal size="ms" show onHide={handleClose}>
      <Modal.Header closeButton>{existingGatewayId ? t("Edit gateway") : t("Create gateway")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Gateway Serial")}</Form.Label>
                <Form.Control
                  placeholder="CBIOT2050X"
                  value={gatewayData.serial}
                  required
                  onChange={handleChange("serial")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Passcode")}</Form.Label>
                <Form.Control
                  placeholder={t("8-letter code")}
                  value={gatewayData.passcode}
                  onChange={handleChange("passcode")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Tailscale Node-ID")}</Form.Label>
                <Form.Control
                  placeholder="Tailscale ID"
                  value={gatewayData.tailscale_node_id}
                  onChange={handleChange("tailscale_node_id")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Internal IP")}</Form.Label>
                <Form.Control
                  placeholder={t("Internal IP")}
                  value={gatewayData.internal_ip}
                  onChange={handleChange("internal_ip")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Node-RED {t("Password")}</Form.Label>
                <Form.Control
                  value={gatewayData.node_red_password}
                  placeholder={t("Password")}
                  onChange={handleChange("node_red_password")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="outline-secondary" onClick={handleClose}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant="primary" type="submit">
                {existingGatewayId ? t("Apply") : t("Create")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SingleIoTGatewayModal;